import React from "react";
import { apiCall } from "../../_services/apiCall";
import config from "../../config/config.json";
import due from "../../img/Due.svg";
import updated from "../../img/updated.svg";
import done from "../../img/shape.svg";
import defaulted from "../../img/Defaulted.svg";
import { useState } from "react";
import { useEffect } from "react";
import TopComponentWaste from "./TopComponentWaste";
import WasteGenerated from "./WasteGenerated";
import WasteRecovered from "./WasteRecovered";
import WasteDisposed from "./WasteDisposed";
import WasteComparison from "./WasteComparison";
import TotalWasteGeneratedByLoc from "./TotalWasteGeneratedByLoc";
import WasteDisposedMulti from "./WwasteDisposedMultiLoc";
import WasteRecoveredMulti from "./WasteRecoveredMulti";
import WasteRecTarget from "./WasteRecTraget";
import WasteCostAnalysis from "./WasteCostAnalysis";
import WaterStorageTrackingUnit from "./WaterStorageTrackingUnit.jsx";
import WwasteCostAnalysisSingle from "./WasteCostAnalysisSingle";
import WasteRecoveredSingle from "./WasteRecoveredSingle";
import WasteDisposalChart from "./WasteDisposalChart";

const Waste = ({
  locationOption,
  timePeriods,
  financialYearId,
  graphData,
  frameworkValue,
}) => {
  const location = 2;
  const [lastWeekAcitivities, setLastWeekAcitivities] = useState();
  const icons = {
    done: done,
    updated: updated,
    due: due,
    pending: defaulted,
  };

  const lastWeekActivity = async () => {
    const { isSuccess, data } = await apiCall(
      `${config.POSTLOGIN_API_URL_COMPANY}lastWeekActivity`,
      {},
      {},
      "GET"
    );
    if (isSuccess) {
      const data = {
        "Total Waste": {
          number: "2354",
          questionId: [],
        },
        "Waste Recovered": {
          number: "123",
          questionId: [],
        },
        "Waste Disposed": {
          number: "57",
          questionId: [],
        },

        message: "Good Evening, Sunil Kumar",
      };
      setLastWeekAcitivities(data);
    }
  };

  useEffect(() => {
    lastWeekActivity();
  }, []);
  return (
    <div className="progress-container">
      {/* <div className="topcompo">
        {lastWeekAcitivities && (
          <TopComponentWaste
            lastWeekAcitivities={lastWeekAcitivities}
            icons={icons}
          />
        )}
      </div> */}

      {locationOption.length === 1 && (
        <div className="d-flex flex-column flex-space-between">
          <div
            className="d-flex flex-row flex-space-between"
            style={{ height: "35vh", marginBottom: "3%" }}
          >
            <WaterStorageTrackingUnit
              consumption={5}
              notGenerated={5}
              maxConsumption={12}
            />
          </div>

          <div
            className="d-flex flex-row flex-space-between"
            style={{ height: "50vh", marginBottom: "3%" }}
          >
            <div className="firsthalfprogressenergy">
              <WasteDisposalChart/>
            </div>
            <div className="secondhalfprogress">
              <div style={{ height: "56%" , marginBottom:"2%"}}>
                <WwasteCostAnalysisSingle
                  consumption={5}
                  notGenerated={5}
                  maxConsumption={12}
                />
              </div>
              <div style={{ height: "40%" }}>
                <WasteRecoveredSingle
                  consumption={5}
                  notGenerated={5}
                  maxConsumption={12}
                />
              </div>
            </div>
          </div>
        </div>
      )}

      {locationOption.length >= 2 && (
        <div className="d-flex flex-column flex-space-between">
          <div
            className="d-flex flex-row flex-space-between"
            style={{ height: "70vh", marginBottom: "3%" }}
          >
            <TotalWasteGeneratedByLoc
              locationOption={locationOption}
              timePeriods={timePeriods}
              financialYearId={financialYearId}
              graphData={graphData}
              frameworkValue={frameworkValue}
            />
          </div>
          <div
            className="d-flex flex-row flex-space-between"
            style={{ height: "60vh", marginBottom: "3%" }}
          >
            {/* <div
          className="firsthalfprogressenergy"
          
        > */}
            <WasteRecoveredMulti
              locationOption={locationOption}
              timePeriods={timePeriods}
              financialYearId={financialYearId}
              graphData={graphData}
              frameworkValue={frameworkValue}
            />

            {/* </div> */}
            {/* <div className="secondhalfprogress" >
        <WasteRecoveredMulti/>
        </div> */}
          </div>
          <div
            className="d-flex flex-row flex-space-between"
            style={{ height: "60vh", marginBottom: "3%" }}
          >
            {/* <div
          className="firsthalfprogressenergy"
          
        > */}
            <WasteDisposedMulti
              locationOption={locationOption}
              timePeriods={timePeriods}
              financialYearId={financialYearId}
              graphData={graphData}
              frameworkValue={frameworkValue}
            />

            {/* </div> */}
            {/* <div className="secondhalfprogress" >
        <WasteRecoveredMulti/>
        </div> */}
          </div>
          {/* <div className="d-flex flex-row flex-space-between"
      style={
        {
          height:"70vh"
        }
      }>
       <div
          className="firsthalfprogressenergy"
          
        >
          <WasteRecTarget/>

        </div>
        <div className="secondhalfprogress" >
          <WasteCostAnalysis/>
        </div>

      </div> */}
        </div>
      )}
    </div>
  );
};

export default Waste;
