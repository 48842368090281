import React from 'react';
import Chart from 'react-apexcharts';

const RepresentationOfWomen = () => {
  const series = [
    {
      name: 'Total Positions',
      data: [120, 150] // Example data for Total Positions
    },
    {
      name: 'Female Positions',
      data: [40, 60] // Example data for Female Positions
    }
  ];

  const options = {
    chart: {
      type: 'bar',
      height: 350,
      stacked: false, // Not stacked
      toolbar: {
        show: false
      }
    },
    plotOptions: {
      bar: {
        horizontal: false,
        barHeight: '50%',
        columnWidth:"30%%",
        distributed: true, // Distributes colors
      }
    },
    xaxis: {
      categories: ['Key Position', 'Normal Position'], // X-axis labels
      labels: {
        style: {
          fontSize: '12px',
          fontWeight: 'bold',
          colors: 'grey',
        }
      }
    },
    yaxis: {
      
    },
    colors: ['#3F88A5', '#B0C4DE'], // Colors for each series
    legend: {
      position: 'bottom',
      horizontalAlign: 'center'
    },
    fill: {
      colors: ['#3F88A5', '#B0C4DE'], // Ensures the same color scheme for the bars
    }
  };

  return (
    <div className="container">
      <div className="header">
        <div className="title">Representation of Women</div>
      </div>
      <div className="chart-container">
        <Chart options={options} series={series} type="bar" height={"100%"} />
      </div>
    </div>
  );
};

export default RepresentationOfWomen;
