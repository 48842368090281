import React from "react";
import { apiCall } from "../../_services/apiCall";
import config from "../../config/config.json";
import due from "../../img/Due.svg";
import updated from "../../img/updated.svg";
import done from "../../img/shape.svg";
import defaulted from "../../img/Defaulted.svg";
import { useState } from "react";
import { useEffect } from "react";
import TopComponentEmission from "./TopComponentEmission";
import LocationWiseEmission from "./LocationWiseEmission";
import GHGEmissions from "./GHGEmissions";
import EmissionAcrossLocation from "./EmissionAcrossLocation";
import ProductWiseEmission from "./ProductWiseEmission";
import ScopeTwo from "./ScopeTwo";
import ComparisonScope from "./ComparisonScope";
import ComparisonAcrossYear from "./ComparisonAcrossYear";
import TotalEmissionGenerated from "./TotalCommissionGranted";
import TotalGHGEmissionPercentage from "./TotalGHGEmission";
import ScopeMultiLocComparison from "./ScopeComMulLoc";
import TotalCarbonEmission from "./TotalCarbonEmission";
import EmissionIntensityOverTime from "./EmissionIIntensityOverTime";
import CarbonEmissionByScope from "./CarbonEmissionByScope";
import LineChartWithOptions from "./LineChartEmission";
import MixedChart from "./MixedChart";
import Speedometer from "./Speedometer";
import DifferentColumnCharts from "../Company Sub Admin/Pages/home/DifferentColumnCharts";
import TotalProductWise from "../Company Sub Admin/Pages/home/TotalProductWise";
import TotalEmissionGeneratedSingle from "./TotalEmissionGenerated";
import ScopeComSingleLoc from "./ScopeComSingleLoc";
import TotalCarbonGeneratedSingle from "./TotalCarbonSingleLoc";
import CarbonEmissionByScopeSingle from "./CarbonEmissionByScopeSingle";
import ProductWiseScopeTwo from "./ProductWiseScopeTwo";
import IntensityOverTime from "./IntensityOverTime";
import ProductWiseEmissionSingle from "./ProductWiseEmissionSingle";
import COTWOReduction from "./COTWOReduction";

const Emission = ({
  locationOption,
  timePeriods,
  financialYearId,
  graphData,
  frameworkValue,
  sectorQuestionAnswerDataForGraph,
}) => {
  const location = 2;
  const [lastWeekAcitivities, setLastWeekAcitivities] = useState();
  const [companyFramework, setCompanyFramework] = useState([]);

  const icons = {
    done: done,
    updated: updated,
    due: due,
    pending: defaulted,
  };

  const lastWeekActivity = async () => {
    const { isSuccess, data } = await apiCall(
      `${config.POSTLOGIN_API_URL_COMPANY}lastWeekActivity`,
      {},
      {},
      "GET"
    );
    if (isSuccess) {
      const data = {
        "Total Emission": {
          number: "2009",
          questionId: [],
        },
        "Scope 1 Emission": {
          number: "332",
          questionId: [],
        },
        "Scope 2 Emission": {
          number: "1677",
          questionId: [],
        },

        message: "Good Evening, Sunil Kumar",
      };
      setLastWeekAcitivities(data);
    }
  };

  useEffect(() => {
    lastWeekActivity();
  }, []);
  useEffect(() => {
    if (frameworkValue && frameworkValue.length) {
      const frameworkId = frameworkValue.map((value) => value.id);
      setCompanyFramework(frameworkId);
    }
  }, [frameworkValue]);

  return (
    <div className="progress-container">
      <div className="topcompo">
        {lastWeekAcitivities && (
          <TopComponentEmission
            lastWeekAcitivities={lastWeekAcitivities}
            icons={icons}
          />
        )}
      </div>
      {locationOption.length === 1 && (
        <div className="d-flex flex-column flex-space-between">
          <div
            className="d-flex flex-row flex-space-between"
            style={{ height: "25vh", marginBottom: "3%" }}
          >
            <TotalEmissionGeneratedSingle />
          </div>
          <div
            className="d-flex flex-row flex-space-between"
            style={{ height: "70vh", marginBottom: "3%" }}
          >
            <div className="firsthalfprogressenergy" style={{ width: "50%" }}>
              <ScopeComSingleLoc title={"Scope 1 and Scope 2 Comparison"} />
            </div>
            <div className="secondhalfprogress" style={{ width: "50%" }}>
              <div style={{ height: "55%" , marginBottom:"2%"  }}>
                <TotalCarbonGeneratedSingle />
              </div>
              <div style={{ height: "45%" }}>
                <CarbonEmissionByScopeSingle
                  notGenerated={15}
                  maxConsumption={40}
                  consumption={10}
                />
              </div>
            </div>
          </div>
          <div
            className="d-flex flex-row flex-space-between"
            style={{ height: "70vh", marginBottom: "3%" }}
          >
            <div className="firsthalfprogressenergy" style={{ width: "50%" }}>
              <div style={{ height: "55%" ,  marginBottom:"2%"}}>
                <ProductWiseScopeTwo
                  notGenerated={15}
                  maxConsumption={40}
                  consumption={10}
                />
              </div>
              <div style={{ height: "45%" , }}>
                <IntensityOverTime />
              </div>
            </div>
            <div className="secondhalfprogress" style={{ width: "50%" }}>
              <ProductWiseEmissionSingle />
            </div>
          </div>
          <div className="d-flex flex-row flex-space-between" style={{height:"30vh"}}>
            <COTWOReduction/>

          </div>
        </div>
      )}
      {locationOption.length >= 2 && (
        <div className="d-flex flex-column flex-space-between">
          {/* <div
            className="d-flex flex-row flex-space-between"
            style={{ height: "70vh", marginBottom: "3%" }}
          >
            <div className="firsthalfprogressenergy">
              <TotalEmissionGenerated />
            </div>
            <div className="secondhalfprogress">
              <TotalGHGEmissionPercentage />
            </div>
          </div>
          <div
            className="d-flex flex-row flex-space-between"
            style={{ height: "70vh", marginBottom: "3%" }}
          >
            <div className="firsthalfprogressenergy">
              <ScopeMultiLocComparison />
            </div>
            <div className="secondhalfprogress">
              <TotalCarbonEmission />
            </div>
          </div> */}
          {/* <div
            className="d-flex flex-row flex-space-between"
            style={{ height: "70vh", marginBottom: "3%" }}
          >
            <div className="firsthalfprogressenergy">
              <EmissionIntensityOverTime />
            </div>
            <div className="secondhalfprogress">
              <CarbonEmissionByScope />
            </div>
          </div> */}
          {companyFramework &&
          companyFramework.length &&
          companyFramework.includes(1) ? (
            <div
              className="d-flex flex-row flex-space-between"
              style={{ height: "70vh", marginBottom: "3%" }}
            >
              <div className="firsthalfprogressenergy">
                <DifferentColumnCharts
                  key={"GHG Emissions"}
                  category={"GHG Emissions"}
                  data={{
                    categories: [["Scope 1"], ["Scope 2"]],
                    series: [
                      {
                        name: "GHG Emissions",
                        data: [
                          Number(
                            JSON.parse(
                              sectorQuestionAnswerDataForGraph[0]["answer"]
                            )[0][1]
                          ) || 0,
                          Number(
                            JSON.parse(
                              sectorQuestionAnswerDataForGraph[0]["answer"]
                            )[1][1]
                          ) || 0,
                        ],
                      },
                    ],
                    direct: true,
                    graphType: "bar",
                    filter: false,
                    unit: "tCO2e",
                    color: ["#ffd9b3", "#ffb366"],
                    questionIds: [
                      sectorQuestionAnswerDataForGraph["questionId"],
                    ],
                  }}
                  // handleApplyFilters={handleApplyFilters}
                  type="PRODUCTWISEPIECHART"
                  size="450"
                />
              </div>
              <div className="secondhalfprogress">
                <TotalProductWise
                  key={"GHG Emissions Comparison"}
                  category={"GHG Emissions Comparison"}
                  data={{
                    categories: [
                      // "0",
                      "2023-2024",
                      "2022-2023",
                    ],
                    series: [
                      {
                        name: "Scope 2 emissions",
                        // type: "line",
                        data: [
                          JSON.parse(
                            sectorQuestionAnswerDataForGraph[0]["answer"]
                          )[1][1] || 0,
                          JSON.parse(
                            sectorQuestionAnswerDataForGraph[0]["answer"]
                          )[1][2] || 0,
                        ],
                      },
                      {
                        name: "Scope 1 emissions",
                        // type: "line",
                        data: [
                          JSON.parse(
                            sectorQuestionAnswerDataForGraph[0]["answer"]
                          )[0][1] || 0,
                          JSON.parse(
                            sectorQuestionAnswerDataForGraph[0]["answer"]
                          )[0][2] || 0,
                        ],
                      },
                    ],
                    direct: true,
                    graphType: "bar",
                    stacked: true,
                    filter: false,
                    unit: "tCO2e",
                    color: ["#ff8c1a", "#ffd9b3", "#ffb366"],
                    questionIds: [[2]],
                  }}
                  // handleApplyFilters={handleApplyFilters}
                  type="PRODUCTWISEPIECHART"
                  size="430"
                />
              </div>
            </div>
          ) : (
            <div
              className="d-flex flex-row flex-space-between"
              style={{ height: "70vh", marginBottom: "3%" }}
            >
              <div className="firsthalfprogressenergy">
                <LineChartWithOptions
                  locationOption={locationOption}
                  timePeriods={timePeriods}
                  financialYearId={financialYearId}
                  graphData={graphData}
                />
              </div>
              <div className="secondhalfprogress">
                <ScopeMultiLocComparison
                  locationOption={locationOption}
                  timePeriods={timePeriods}
                  financialYearId={financialYearId}
                  graphData={graphData}
                />
              </div>
            </div>
          )}
          {/* <div
            className="d-flex flex-row flex-space-between"
            style={{ height: "70vh", marginBottom: "3%" }}
          >
            <div className="firsthalfprogressenergy">
              <LineChartWithOptions locationOption = {locationOption} timePeriods = {timePeriods} financialYearId = {financialYearId} graphData = {graphData} />
            </div>
            <div className="secondhalfprogress">
              <ScopeMultiLocComparison   locationOption = {locationOption} timePeriods = {timePeriods} financialYearId = {financialYearId} graphData = {graphData}/>
            </div>
          </div>
          <div
            className="d-flex flex-row flex-space-between"
            style={{ height: "70vh", marginBottom: "3%" }}
          >
            <div className="firsthalfprogressenergy">
              <MixedChart />
            </div>
            <div className="secondhalfprogress">
              <Speedometer />
            </div>
          </div> */}
        </div>
      )}
    </div>
  );
};

export default Emission;
