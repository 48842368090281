import React from "react";
import { apiCall } from "../../_services/apiCall";
import config from "../../config/config.json";
import due from "../../img/Due.svg";
import updated from "../../img/updated.svg";
import done from "../../img/shape.svg";
import defaulted from "../../img/Defaulted.svg";
import { useState } from "react";
import { useEffect } from "react";
import TopComponentEmission from "../Emission/TopComponentEmission";
import WaterWithdrawal from "./WaterWithdrawal";
import TopComponentWater from "./TopComponentWater";
import WaterComparison from "./WaterComparison";
import WaterRevenueIntensity from "./WaterRevenueIntensity";
import WaterStorageTracking from "./WaterUnit";
import NegativeWaterConsumption from "./NegativeWaterConsumption";
import WaterStorageTrackingUnit from "./WwaterStorageTracking";
import WwaterWithdrawalSingle from "./WaterWithdrawalSingle";
import CompareWaterConsumption from "./CompareWaterConsumption";
import WaterRevenueIntensitySingle from "./WaterRevenueIntensitySingle";
import WwaterStorageTrackingSingle from "./WaterStorageTrackingSingle";
import WaterSustainable from "./WaterSustainable";
import NegativeWaterSingle from "./NegativeWaterConsumptionSinfle";

const Water = ({
  locationOption,
  timePeriods,
  financialYearId,
  graphData,
  frameworkValue,
}) => {
  const [lastWeekAcitivities, setLastWeekAcitivities] = useState();
  const activeGraph = 2;
  const icons = {
    0: done,
    1: updated,
    2: due,
    3: defaulted,
  };

  const lastWeekActivity = async () => {
    const { isSuccess, data } = await apiCall(
      `${config.POSTLOGIN_API_URL_COMPANY}lastWeekActivity`,
      {},
      {},
      "GET"
    );
    if (isSuccess) {
      const data = {
        "Total Water": {
          number: "1422 M",
          questionId: [],
        },

        message: "Good Evening, Sunil Kumar",
      };
      setLastWeekAcitivities(data);
    }
  };

  useEffect(() => {
    lastWeekActivity();
  }, []);
  return (
    <div className="progress-container">
      {/* <div className="topcompo">
      {lastWeekAcitivities && (
        <TopComponentWater
          lastWeekAcitivities={lastWeekAcitivities}
          icons={icons}
        />
      )}
    </div> */}
      {locationOption.length === 1 && (
        <div className="d-flex flex-column flex-space-between">
          <div
            className="d-flex flex-row flex-space-between"
            style={{ height: "60vh", marginBottom: "3%" }}
          >
            <div className="firsthalfprogressenergy" style={{ width: "50%" }}>
              <WwaterWithdrawalSingle title={"Water Withdrawal"} />
            </div>
            <div className="secondhalfprogress" style={{ width: "50%" }}>
              <div style={{ height: "55%", marginBottom:"2%" }}>
                <CompareWaterConsumption
                  consumption={5}
                  notGenerated={7}
                  maxConsumption={20}
                />
              </div>
              <div style={{ height: "45%" }}>
                <WaterRevenueIntensitySingle
                  consumption={5}
                  notGenerated={8}
                  maxConsumption={25}
                />
              </div>
            </div>
          </div>
          <div
            className="d-flex flex-row flex-space-between"
            style={{ height: "30vh", marginBottom: "3%" }}
          >
           <WwaterStorageTrackingSingle consumption={10} notGenerated={20} maxConsumption={40}/>
          </div>
          <div
            className="d-flex flex-row flex-space-between"
            style={{ height: "45vh", marginBottom: "3%" }}
          >
            <div className="secondhalfprogress">
              <WaterSustainable/>
             
            </div>
            <div className="firsthalfprogressenergy" style={{marginLeft:"5%"}}>
            <NegativeWaterSingle consumption={10} notGenerated={20} maxConsumption={40}/>
            </div>

          </div>
        </div>
      )}
      {/* {
      locationOption && locationOption.length === 1 && 
      <div className='d-flex flex-column flex-space-between'>
        <div
        className="d-flex flex-row flex-space-between"
        style={{ height: "70vh",marginBottom:"3%" }}
      >
         <div className="firsthalfprogressenergy" style={{width:"50%"}}>
            <LocationWiseEmission/>
            <WaterWithdrawal/>

        </div>
        <div className="secondhalfprogress" style={{width:"50%"}}>
            <WaterComparison/>
        </div>

      </div>
      </div>
    } */}
      {locationOption.length >= 2 && (
        <div className="d-flex flex-column flex-space-between">
          <div
            className="d-flex flex-row flex-space-between"
            style={{ height: "70vh", marginBottom: "3%" }}
          >
            {/* <div className="firsthalfprogressenergy" style={{width:"50%"}}> */}
            {/* <LocationWiseEmission/> */}
            {/* <WaterWithdrawal locationOption = {locationOption} timePeriods = {timePeriods} financialYearId = {financialYearId} graphData = {graphData} /> */}

            {/* </div> */}
            {/* <div className="secondhalfprogress" style={{width:"50%"}}> */}
            <WaterComparison
              locationOption={locationOption}
              timePeriods={timePeriods}
              financialYearId={financialYearId}
              graphData={graphData}
              frameworkValue={frameworkValue}
            />
            {/* </div> */}
          </div>

          {/* <div
        className="d-flex flex-row flex-space-between"
        style={{ height: "70vh",marginBottom:"3%" }}
      >
        <div className="firsthalfprogressenergy" style={{width:"50%"}}>
            <WaterRevenueIntensity/>
          
        </div>
        <div className="secondhalfprogress" style={{width:"50%"}}>
        <WaterStorageTracking/>
        </div>
      </div>
      <div
        className="d-flex flex-row flex-space-between"
        style={{ height: "70vh",marginBottom:"3%" }}
      >
        <div className="firsthalfprogressenergy" style={{width:"50%"}}>
        <WaterStorageTracking/>
       
          
        </div>
        <div className="secondhalfprogress" style={{width:"50%"}}>
          <NegativeWaterConsumption/>
        </div>
      </div> */}
        </div>
      )}
    </div>
  );
};

export default Water;
