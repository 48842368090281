// import React, { useEffect, useState, createContext, useContext, } from "react";
// import { NavLink, Link, useLocation, Redirect } from "react-router-dom";
// import foot_Logo from "../../img/logol.png";
// import head_Logo from "../../img/Riu_TM_Logo.png";
// import { apiCall } from "../../_services/apiCall";
// import "./common.css";
// import "./sidebar.css";
// import config from "../../config/config.json";
// import { PermissionMenuContext } from "../../contextApi/permissionBasedMenuContext";
// import { authenticationService } from "../../_services/authentication";
// import { history } from "../../_helpers/history";
// import { FiLogOut } from "react-icons/fi";
// import { set } from "lodash";
// import down from "../../img/DownArrow.svg";

// export const AuditContext = createContext();
// export const useAudit = () => useContext(AuditContext);

// // export const ReportContext = createContext();
// // export const useReport =()=>useContext(ReportContext)



// export const AuditProvider = ({ children }) => {
//   const location = useLocation();
//   const [auditModuleNames, setAuditModuleNames] = useState([]);
//   const [auditModule, setAuditModule] = useState([]);
//   const [auditAssignedTo, setAuditAssignedTo] = useState([]);
//   const [sourceData, setSourceData] = useState();

//   const getSource = async () => {
//     const { isSuccess, data } = await apiCall(
//       `${config.POSTLOGIN_API_URL_COMPANY}getSource`,
//       {},
//       {},
//       "GET"
//     );
//     if (isSuccess) {
//       console.log("hihihhahaha", data.data)
//       setSourceData(data?.data);
//     }
//   };

//   useEffect(() => {
//     getSource()
//     getAuditListing()


//   }, [])

//   const getFinancialYear = async () => {
//     const { isSuccess, data } = await apiCall(
//       `${config.POSTLOGIN_API_URL_COMPANY}getFinancialYear`,
//       {},
//       {}
//     );

//     if (isSuccess) {

//       return (data?.data[data.data.length - 1]?.id);

//     }
//   };


//   const getAuditListing = async () => {
//     try {
//       const response = await apiCall(
//         `${config.POSTLOGIN_API_URL_COMPANY}getAuditListing`,
//         {},
//         {
//           financialYearId: await getFinancialYear(),
//           // frameworkIds: [1] 
//         },
//         "GET"
//       );
//       if (response.isSuccess) {
//         const data = response.data;
//         //console.log("auditListing", data);
//         const tmpData = location.state?.reportingQuestion.length ? data?.data.filter(item => location.state?.reportingQuestion.includes(item.question.questionId)) : data?.data;


//         // Set to collect unique module names
//         const moduleNamesSet = new Set();

//         // Object to group data by module
//         const groupedData = tmpData.reduce((acc, item) => {
//           const moduleName = item.question?.moduleName || "Unknown Module";

//           // Add module name to the set
//           moduleNamesSet.add(moduleName);

//           // Initialize array if not already done
//           if (!acc["All Module"]) {
//             acc["All Module"] = [];
//           }
//           acc["All Module"].push(item);
//           if (!acc[moduleName]) {
//             acc[moduleName] = [];
//           }

//           // Add item to the module's array
//           acc[moduleName].push(item);
//           return acc;
//         }, {});

//         const moduleNamesList = Array.from(moduleNamesSet);

//         setAuditModule(groupedData);
//         console.log("shamik", groupedData)
//         setAuditModuleNames(moduleNamesList);

//         const assignedToData = data.getAssignedDetails

//         setAuditAssignedTo(assignedToData);
//       }
//     } catch (error) {
//       console.error("Error fetching data:", error);
//     }
//   };

//   return (
//     <AuditContext.Provider value={{ getAuditListing, auditModule, auditModuleNames, auditAssignedTo }}>
//       {children}
//     </AuditContext.Provider>
//   );
// };

// // export const ReportingProvider = ({children})=>{

// //   const [frameworkValue,setFrameworkValue]=useState();
// //   const [moduleNames,setModuleNames]=useState();
// //   const [module,setModule]=useState();
// //   const [assignedTo,setAssignedTo]=useState()

// //   const getFinancialYear = async () => {
// //     const { isSuccess, data } = await apiCall(
// //       `${config.POSTLOGIN_API_URL_COMPANY}getFinancialYear`,
// //       {},
// //       {}
// //     );

// //     if (isSuccess) {

// //       return (data?.data[data.data.length - 1]?.id);

// //     }
// //   };

// //   const fetchFrameworkApi = async () => {
// //     const { isSuccess, data } = await apiCall(
// //       `${config.POSTLOGIN_API_URL_COMPANY}getFramework`,
// //       {},
// //       { type: "ALL" }
// //     );
// //     if (isSuccess) {
// //       const frameworkIds = data?.data.map((item) => item.id);
// //       setFrameworkValue(frameworkIds);
// //       return data?.data.map((item) => item.id);
// //     }
// //   };


// //   const getReportingQuestions = async () => {
// //     try {
// //       const response = await apiCall(
// //         `${config.POSTLOGIN_API_URL_COMPANY}getReportingQuestion`,
// //         {},
// //         {
// //           financialYearId: await getFinancialYear(),
// //           frameworkIds: await fetchFrameworkApi()
// //         },
// //         "GET"
// //       );
// //       if (response.isSuccess) {
// //         const data = response.data;

// //         const moduleNames = [...new Set(data.data.map(item => item.moduleName))];

// //         setModuleNames(moduleNames);
// //         const groupedByModuleName = data.data.reduce((acc, item) => {
// //           if (!acc["All Module"]) {
// //             acc["All Module"] = [];
// //           }
// //           acc["All Module"].push(item);
// //           if (!acc[item.moduleName]) {
// //             acc[item.moduleName] = [];
// //           }
// //           acc[item.moduleName].push(item);


// //           return acc;
// //         }, {});

// //         setModule(groupedByModuleName);

// //         const assignedToData = data.assignedDetail
// //         setAssignedTo(assignedToData); // Assuming `setAssignedTo` is a state sette

// //       }
// //     } catch (error) {
// //       console.error("Error fetching data:", error);
// //     }
// //   };

// //   useEffect(() => {
// //     getReportingQuestions()


// //   }, [])

// //   return(
// // <ReportContext.Provider value={{ getReportingQuestions, module, moduleNames, assignedTo }}>
// //       {children}
// //     </ReportContext.Provider>
// //   )

// // }

// const Sidebar = (props) => {
//   const location = useLocation();

//   const financeObjct = props?.financeObjct;
//   const contextType = useContext(PermissionMenuContext);
//   const [menuList, setMenuList] = useState([]);
//   const [frameworkValue, setFrameworkValue] = useState([]);
//   const [moduleNames, setModuleNames] = useState([]);
//   const [module, setModule] = useState([]);
//   const [isSubmenuVisible, setIsSubmenuVisible] = useState(false);
//   const [assignedTo, setAssignedTo] = useState([]);
//   const [redirectTo, setRedirectTo] = useState(null);
//   const [isAuditSubmenuVisible, setIsAuditSubmenuVisible] = useState(false);
//   const [isAuditListingSubmenuVisible, setIsAuditListingSubmenuVisible] = useState(false);
//   const [auditModuleNames, setAuditModuleNames] = useState([]);
//   const [auditModule, setAuditModule] = useState([]);
//   const [auditAssignedTo, setAuditAssignedTo] = useState([]);
//   const [sourceData, setSourceData] = useState();
//   const [isRotated, setIsRotated] = useState(false);
//   const [isAuditRotated, setIsAuditRotated] = useState(false);
//   const [isAuditSubmenuRotated, setIsAuditSubmenuRotated] = useState(false)


//   // useEffect(()=>{
//   //   const storedIsSubmenuVisible = localStorage.getItem('isSubmenuVisible');
//   //   if (storedIsSubmenuVisible !== null) {
//   //     setIsSubmenuVisible(JSON.parse(storedIsSubmenuVisible));
//   //   }

//   // },[])



//   const getAuditListing = async () => {
//     try {
//       const response = await apiCall(
//         `${config.POSTLOGIN_API_URL_COMPANY}getAuditListing`,
//         {},
//         {
//           financialYearId: await getFinancialYear(),
//           // frameworkIds: [1] 
//         },
//         "GET"
//       );
//       if (response.isSuccess) {
//         const data = response.data;
//         const tmpData = location.state?.reportingQuestion.length ? data?.data.filter(item => location.state?.reportingQuestion.includes(item.question.questionId)) : data?.data;
//         //console.log("auditListing", data);

//         // Set to collect unique module names
//         const moduleNamesSet = new Set();

//         // Object to group data by module
//         const groupedData = tmpData.reduce((acc, item) => {
//           const moduleName = item.question?.moduleName || "Unknown Module";

//           // Add module name to the set
//           moduleNamesSet.add(moduleName);

//           // Initialize array if not already done
//           if (!acc["All Module"]) {
//             acc["All Module"] = [];
//           }
//           acc["All Module"].push(item);
//           if (!acc[moduleName]) {
//             acc[moduleName] = [];
//           }

//           // Add item to the module's array
//           acc[moduleName].push(item);
//           return acc;
//         }, {});

//         const moduleNamesList = Array.from(moduleNamesSet);

//         setAuditModule(groupedData);
//         console.log(groupedData, "sasdfhtjhgfdsafghgfdsa")
//         setAuditModuleNames(moduleNamesList);

//         const assignedToData = data.getAssignedDetails

//         setAuditAssignedTo(assignedToData);
//       }
//     } catch (error) {
//       console.error("Error fetching data:", error);
//     }
//   };
//   const getSource = async () => {
//     const { isSuccess, data } = await apiCall(
//       `${config.POSTLOGIN_API_URL_COMPANY}getSource`,
//       {},
//       {},
//       "GET"
//     );
//     if (isSuccess) {
//       console.log("hihihhahaha", data.data)
//       setSourceData(data?.data);
//     }
//   };



//   const toggleSubmenu = async () => {

//     getSource()

//     // Await the completion of getReportingQuestions
//     //  await getReportingQuestions();
//     await getReportingQuestions();
//     const newIsSubmenuVisible = !isSubmenuVisible;

//     setIsSubmenuVisible(newIsSubmenuVisible);

//     setIsRotated(!isRotated);

//   };


//   useEffect(() => {
//     const fetchData = async () => {
//       try {
//         if (location.pathname.includes("reporting-modules")) {
//           getReportingQuestions();
//         } else if (location.pathname.includes("audit-listing")) {
//           await getAuditListing(); // Call your async function here

//         }
//       } catch (error) {
//         console.error("Error fetching reporting questions:", error);
//       }
//     };

//     fetchData(); // Invoke the async function
//   }, [])
//   useEffect(() => {

//     const fetchData = async () => {
//       try {
//         if (location.pathname.includes("reporting-modules")) {
//           getReportingQuestions();
//         } else if (location.pathname.includes("audit-listing")) {
//           getAuditListing(); // Call your async function here

//         }
//       } catch (error) {
//         console.error("Error fetching reporting questions:", error);
//       }
//     };

//     fetchData(); // Invoke the async function
//   }, [location.state?.reportingQuestion])

//   const toggleAuditListingSubmenu = () => {
//     getSource()
//     setIsAuditListingSubmenuVisible(!isAuditSubmenuVisible);
//     setIsAuditSubmenuRotated(!isAuditSubmenuRotated)
//   };

//   const toggleAuditSubmenu = () => {
//     console.log("clicked")
//     getAuditListing()
//     setIsAuditSubmenuVisible(!isAuditSubmenuVisible);
//     setIsAuditRotated(!isAuditRotated)
//   };
//   const fetchFrameworkApi = async () => {
//     const { isSuccess, data } = await apiCall(
//       `${config.POSTLOGIN_API_URL_COMPANY}getFramework`,
//       {},
//       { type: "ALL" }
//     );
//     if (isSuccess) {
//       const frameworkIds = data?.data.map((item) => item.id);
//       setFrameworkValue(frameworkIds);
//       return data?.data.map((item) => item.id);
//     }
//   };

//   const toUrlFriendlyName = (name) => {
//     return name.toLowerCase().replace(/\s+/g, '-');
//   };

//   const getFinancialYear = async () => {
//     const { isSuccess, data } = await apiCall(
//       `${config.POSTLOGIN_API_URL_COMPANY}getFinancialYear`,
//       {},
//       {}
//     );

//     if (isSuccess) {

//       return (data?.data[data.data.length - 1]?.id);

//     }
//   };


//   const getReportingQuestions = async () => {
//     try {
//       const response = await apiCall(
//         `${config.POSTLOGIN_API_URL_COMPANY}getReportingQuestion`,
//         {},
//         {
//           financialYearId: await getFinancialYear(),
//           frameworkIds: await fetchFrameworkApi()
//         },
//         "GET"
//       );
//       if (response.isSuccess) {
//         const data = response.data;
//         const tmpData = location.state?.reportingQuestion.length ? data?.data.filter(item => location.state?.reportingQuestion.includes(item.questionId)) : data?.data;
//         const moduleNames = [...new Set(tmpData.map(item => item.moduleName))];

//         setModuleNames(moduleNames);
//         const groupedByModuleName = tmpData.reduce((acc, item) => {
//           if (!acc["All Module"]) {
//             acc["All Module"] = [];
//           }
//           acc["All Module"].push(item);
//           if (!acc[item.moduleName]) {
//             acc[item.moduleName] = [];
//           }
//           acc[item.moduleName].push(item);


//           return acc;
//         }, {});

//         setModule(groupedByModuleName);
//         console.log(groupedByModuleName, "dsfghfdswaesgfhjytre")
//         const assignedToData = data.assignedDetail
//         setAssignedTo(assignedToData);

//       }
//     } catch (error) {
//       console.error("Error fetching data:", error);
//     }
//   };


//   useEffect(() => {
//     const settingsMenu = JSON.parse(localStorage.getItem("menu"));
//     fetchFrameworkApi()
//     setMenuList(settingsMenu);
//   }, [contextType.state]);


//   useEffect(() => {
//     if (location.pathname.includes("reporting-modules")) {
//       setIsSubmenuVisible(true);
//     } else if (location.pathname.includes("audit-listing")) {
//       console.log('enter in this section')
//       // setIsAuditListingSubmenuVisible(true);
//       // toggleAuditListingSubmenu();
//       handleClick()
//       // toggleAuditListingSubmenu();
//     }
//   }, [location.pathname, module, auditModule]);


//   const logout = () => {
//     authenticationService.logout();
//     history.push("/");
//     localStorage.clear();
//   };

//   const currentURL = window.location.href;
//   const splitURL = currentURL.split("/");
//   const activeURL = splitURL[4];
//   const { Defaults } = props;

//   const handleClick = () => {
//     toggleAuditListingSubmenu(); // Toggle the submenu if needed

//     setRedirectTo({
//       pathname: `/audit-listing/${toUrlFriendlyName('all-module')}`,
//       state: {
//         auditModuleData: auditModule["All Module"],
//         auditAssignedTo: auditAssignedTo,
//         sourceData: sourceData,
//       }
//     });
//   };

//   if (redirectTo) {
//     return <>
    
//     <div style={{ boxSizing: "border-box", width: "100%", height: "100vh", overflowY: "auto" }}>
//       <div className="d-flex p-10" id="wrapper" style={{ boxSizing: "border-box", width: "100%", maxWidth: "100%", height: "100%" }}>
//         <div className="bg-white w-100 d-flex flex-column h-100">
//           <div className="logo_text" style={{ width: "100%", overflow: "hidden", maxWidth: "100%" }}>
//             <NavLink to="/home" style={{ maxWidth: "100%" }}>
//               <img src={head_Logo} alt="" style={{ width: "100%" }} />{" "}
//             </NavLink>
//           </div>


//           <div className="list-group list-group-flush">
//             <div className="route-dom p-2">
//               <div className="overView">
//                 <div
//                   style={{
//                     paddingLeft: "11%",
//                     paddingRight: "5%",
//                     paddingBottom: "2%",
//                     borderRadius: 8,
//                     flexDirection: "column",
//                     justifyContent: "center",
//                     alignItems: "flex-start",
//                     display: "inline-flex",
//                   }}
//                 >
//                   <div
//                     style={{
//                       alignSelf: "stretch",
//                       color: "rgba(28, 28, 28, 0.40)",
//                       fontSize: 14,
//                       fontFamily: "Open Sans",
//                       fontWeight: "400",


//                       wordWrap: "break-word",
//                     }}
//                   >
//                     Dashboards
//                   </div>
//                 </div>
//                 <ul className="home_icon_img">
//                   {menuList?.slice(0, 1).map((data, index) => (
//                     <li key={index}>
//                       <NavLink
//                         activeClassName="active"
//                         to={`/${data?.url}`}
//                         className="list-group-item list-group-item-action list-group-item-light p-3 nop"
//                       >
//                         {activeURL === data?.url ? (
//                           <img
//                             src={`${config.BASE_URL}${data?.activeIcon}`}
//                             alt=""
//                           />
//                         ) : (
//                           <img
//                             src={`${config.BASE_URL}${data?.inactiveIcon}`}
//                             alt=""
//                           />
//                         )}

//                         <span className="home_boom">Overview</span>
//                       </NavLink>
//                     </li>
//                   ))}
//                 </ul>
//               </div>
//               <div className="pages" style={{ maxHeight: "calc(100vh - 200px)", overflowY: "auto" }}>
//                 <div
//                   style={{
//                     paddingLeft: "11%",
//                     paddingRight: "5%",
//                     paddingBottom: "2%",
//                     borderRadius: 8,
//                     flexDirection: "column",
//                     justifyContent: "center",
//                     alignItems: "flex-start",
//                     display: "inline-flex",
//                   }}
//                 >
//                   <div
//                     style={{
//                       alignSelf: "stretch",
//                       color: "rgba(28, 28, 28, 0.40)",
//                       fontSize: 14,
//                       fontFamily: "Open Sans",
//                       fontWeight: "400",
//                       wordWrap: "break-word",
//                     }}
//                   >
//                     Pages
//                   </div>
//                 </div>


//                 <ul className="home_icon_img">
//                   {menuList?.slice(1).map((data, index) => {
//                     if (data.caption === "Reporting Module") {
//                       return (
//                         <li key={index}>
//                           <NavLink
//                             onClick={toggleSubmenu}
//                             className="reporting-module
//                            list-group-item-action
//                             list-group-item-light 
                            
//                             nop 
//                             "
//                             onMouseEnter={(e) => e.currentTarget.style.cursor = 'pointer'}
//                             onMouseLeave={(e) => e.currentTarget.style.cursor = 'default'}
//                             to={{
//                               pathname: `/reporting-modules/${toUrlFriendlyName('all-module')}`,
//                               state: { moduleData: module['All Module'], assignedTo: assignedTo, sourceData: sourceData }
//                             }}
//                             style={{
//                               display: "flex",
//                               padding: "0.65rem",
//                               borderRadius: "10px",
//                               backgroundColor: isRotated ? '#3F88A5' : 'transparent',
//                               color: isRotated ? 'white' : 'inherit',
//                               transition: 'background-color 0.3s ease',
//                             }} >
//                             <img src={`${config.BASE_URL}${data?.inactiveIcon}`} alt="" />
//                             <span className="home_boom">{data.caption}</span>

//                             <div style={{
//                               marginLeft: '24%',
//                               marginTop: "2%",
//                               height: "10px", width: "20px",
//                               color: isRotated ? "white" : "#3F88A5",
//                               transform: isRotated ? 'rotate(180deg)' : 'rotate(0deg)',
//                               transition: 'transform 0.3s ease', // Smooth transition for the rotation
//                             }}>
//                               <svg width="19" height="10" viewBox="0 0 19 10" fill="currentcolor" xmlns="http://www.w3.org/2000/svg">
//                                 <path id="Vector" stroke="currentcolor" d="M8.73823 9.70349C9.0194 9.95308 9.33455 10 9.50032 10C9.68532 10 9.97862 9.9545 10.2614 9.70349L18.7262 1.54155C19.1137 1.16804 19.0866 0.58669 18.666 0.242975C18.245 -0.100919 17.5903 -0.0759709 17.2033 0.296641L9.5 7.72292L1.79695 0.296641C1.40913 -0.0770478 0.754206 -0.10074 0.334037 0.242975C-0.0865365 0.58669 -0.113631 1.16804 0.27358 1.54155L8.73823 9.70349Z" fill="#currentcolor" />
//                               </svg>


//                             </div>


//                           </NavLink>
//                           {/* Submenu */}
//                           {isSubmenuVisible && (
//                             <ul className="submenu" style={{ marginTop: "10px" }}>
//                               {Object.keys(module).map((moduleName, idx) => (
//                                 <li key={idx} className="submenu-item">
//                                   <NavLink
//                                     to={{
//                                       pathname: `/reporting-modules/${toUrlFriendlyName(moduleName)}`,
//                                       state: { moduleData: module[moduleName], assignedTo: assignedTo, sourceData: sourceData }
//                                     }}
//                                     className="list-group-item list-group-item-action list-group-item-light p-2 nop"
//                                     style={{ background: "#E2EAEC", borderRadius: "5px" }}
//                                   >
//                                     {moduleName}
//                                   </NavLink>
//                                 </li>
//                               ))}
//                             </ul>
//                           )}
//                         </li>
//                       );
//                     }

//                     if (data.caption === "Audit") {
//                       return (


//                         <li key={index}>
//                           <div
//                             onClick={toggleAuditSubmenu}
//                             className="audit-module list-group-item-action list-group-item-light  nop"
//                             onMouseEnter={(e) => (e.currentTarget.style.cursor = "pointer")}
//                             onMouseLeave={(e) => (e.currentTarget.style.cursor = "default")}
//                             style={{
//                               display: "flex",
//                               padding: "0.65rem",

//                               borderRadius: "10px",
//                               backgroundColor: isAuditRotated ? '#3F88A5' : 'transparent', // Conditionally set the background color
//                               color: isAuditRotated ? 'white' : 'inherit', // Optionally, change text color for better visibility
//                               transition: 'background-color 0.3s ease', // Smooth transition for background color change
//                             }}
//                           >
//                             <img src={`${config.BASE_URL}${data?.inactiveIcon}`} alt="" />
//                             <span className="home_boom">{data?.caption}</span>
//                             <div style={{
//                               marginLeft: '60%',
//                               marginTop: "1%",
//                               height: "10px", width: "20px",
//                               color: isAuditRotated ? "white" : "#3F88A5",
//                               transform: isAuditRotated ? 'rotate(180deg)' : 'rotate(0deg)',
//                               transition: 'transform 0.3s ease', // Smooth transition for the rotation
//                             }}>
//                               <svg width="19" height="10" viewBox="0 0 19 10" fill="currentcolor" xmlns="http://www.w3.org/2000/svg">
//                                 <path id="Vector" stroke="currentcolor" d="M8.73823 9.70349C9.0194 9.95308 9.33455 10 9.50032 10C9.68532 10 9.97862 9.9545 10.2614 9.70349L18.7262 1.54155C19.1137 1.16804 19.0866 0.58669 18.666 0.242975C18.245 -0.100919 17.5903 -0.0759709 17.2033 0.296641L9.5 7.72292L1.79695 0.296641C1.40913 -0.0770478 0.754206 -0.10074 0.334037 0.242975C-0.0865365 0.58669 -0.113631 1.16804 0.27358 1.54155L8.73823 9.70349Z" fill="#currentcolor" />
//                               </svg>


//                             </div>
//                           </div>
//                           {isAuditSubmenuVisible && (
//                             <ul className="submenu" style={{ marginTop: "10px" }}>
//                               <li className="submenu-item">
//                                 <div
//                                   onClick={handleClick}
//                                   to={{
//                                     pathname: `/audit-listing/${toUrlFriendlyName('all-module')}`,
//                                     state: { auditModuleData: auditModule["All Module"], auditAssignedTo: auditAssignedTo, sourceData: sourceData },
//                                   }}
//                                   className="list-group-item list-group-item-action list-group-item-light p-2 nop"
//                                   style={{
//                                     //  display: "flex", 
//                                     cursor: "pointer", border: "none ", background: "#E2EAEC", borderRadius: "5px"
//                                   }}
//                                 >
//                                   <div style={{ display: 'flex' }}>
//                                     <div>
//                                       Audit Listing

//                                     </div>
//                                     <div style={{
//                                       marginLeft: '43.5%',
//                                       marginTop: "2%",
//                                       height: "8px", width: "16px",
//                                       color: isAuditSubmenuRotated ? "black" : "black",
//                                       transform: isAuditSubmenuRotated ? 'rotate(180deg)' : 'rotate(0deg)',
//                                       transition: 'transform 0.3s ease', // Smooth transition for the rotation
//                                     }}>
//                                       <svg width="19" height="10" viewBox="0 0 19 10" fill="currentcolor" xmlns="http://www.w3.org/2000/svg">
//                                         <path id="Vector" stroke="currentcolor" d="M8.73823 9.70349C9.0194 9.95308 9.33455 10 9.50032 10C9.68532 10 9.97862 9.9545 10.2614 9.70349L18.7262 1.54155C19.1137 1.16804 19.0866 0.58669 18.666 0.242975C18.245 -0.100919 17.5903 -0.0759709 17.2033 0.296641L9.5 7.72292L1.79695 0.296641C1.40913 -0.0770478 0.754206 -0.10074 0.334037 0.242975C-0.0865365 0.58669 -0.113631 1.16804 0.27358 1.54155L8.73823 9.70349Z" fill="#currentcolor" />
//                                       </svg>


//                                     </div>

//                                   </div>



//                                 </div>
//                                 {isAuditListingSubmenuVisible && (
//                                   <ul className="submenu" style={{ marginTop: "10px", paddingLeft: "15px" }}>
//                                     {Object.keys(auditModule).map((moduleName, idx) => (
//                                       <li key={idx} className="submenu-item">
//                                         <NavLink
//                                           to={{
//                                             pathname: `/audit-listing/${toUrlFriendlyName(moduleName)}`,
//                                             state: { auditModuleData: auditModule[moduleName], auditAssignedTo: auditAssignedTo, sourceData: sourceData },
//                                           }}
//                                           className="list-group-item list-group-item-action list-group-item-light p-2 nop"
//                                           style={{ borderRadius: "10px" }}
//                                         >
//                                           {moduleName}
//                                         </NavLink>
//                                       </li>
//                                     ))}
//                                   </ul>
//                                 )}
//                               </li>
//                               <li className="submenu-item">
//                                 <NavLink
//                                   to="/audit-history"
//                                   className="list-group-item list-group-item-action list-group-item-light p-2 nop"
//                                   style={{ background: "#E2EAEC", borderRadius: "5px" }}
//                                 >
//                                   Audit History
//                                 </NavLink>
//                               </li>
//                             </ul>
//                           )}
//                         </li>

//                       )
//                     }





//                     // if (data.caption === "Audit") {
//                     //   return (
//                     //     <li key={index}>
//                     //       <div
//                     //         onClick={toggleAuditSubmenu}
//                     //         className="audit-module list-group-item-action list-group-item-light p-3 nop"
//                     //         onMouseEnter={(e) => (e.currentTarget.style.cursor = "pointer")}
//                     //         onMouseLeave={(e) => (e.currentTarget.style.cursor = "default")}
//                     //       >
//                     //         <img src={`${config.BASE_URL}${data?.inactiveIcon}`} alt="" />
//                     //         <span className="home_boom">{data.caption}</span>
//                     //       </div>
//                     //       {isAuditSubmenuVisible && (
//                     //         <ul className="submenu" style={{ marginTop: "10px" }}>
//                     //           <li className="submenu-item">
//                     //             <div
//                     //               onClick={toggleAuditListingSubmenu}
//                     //               className="list-group-item list-group-item-action list-group-item-light p-2 nop"
//                     //               style={{ cursor: "pointer", border: "none " }}
//                     //             >
//                     //               Audit Listing
//                     //               {isAuditListingSubmenuVisible && (
//                     //                 <ul className="submenu" style={{ marginTop: "10px", paddingLeft: "15px" }}>
//                     //                   {Object.keys(auditModule).map((moduleName, idx) => (
//                     //                     <li key={idx} className="submenu-item">
//                     //                       <NavLink
//                     //                         to={{
//                     //                           pathname: `/audit-listing/${toUrlFriendlyName(moduleName)}`,
//                     //                           state: { auditModuleData: auditModule[moduleName], auditAssignedTo: auditAssignedTo ,sourceData:sourceData},
//                     //                         }}
//                     //                         className="list-group-item list-group-item-action list-group-item-light p-2 nop"
//                     //                       >
//                     //                         {moduleName}
//                     //                       </NavLink>
//                     //                     </li>
//                     //                   ))}
//                     //                 </ul>
//                     //               )}
//                     //             </div>
//                     //           </li>
//                     //           <li className="submenu-item">
//                     //             <NavLink
//                     //               to="/audit-history"
//                     //               className="list-group-item list-group-item-action list-group-item-light p-2 nop"
//                     //             >
//                     //               Audit History
//                     //             </NavLink>
//                     //           </li>
//                     //         </ul>
//                     //       )}
//                     //     </li>
//                     //   );
//                     // }
//                     return (
//                       <li key={index}>
//                         <NavLink
//                           activeClassName="active"
//                           to={`/${data?.url}`}
//                           className="list-group-item list-group-item-action list-group-item-light nop"
//                           style={{
//                             padding: "0.65rem",
//                           }}
//                         >
//                           {activeURL === data?.url ? (
//                             <img src={`${config.BASE_URL}${data?.activeIcon}`} alt="" />
//                           ) : (
//                             <img src={`${config.BASE_URL}${data?.inactiveIcon}`} alt="" />
//                           )}
//                           <span className="home_boom">{data.caption}</span>
//                         </NavLink>
//                       </li>
//                     );
//                   })}
//                   <li>
//                     <NavLink
//                       to="/"
//                       onClick={logout}
//                       isActive={() => false}
//                       className="list-group-item list-group-item-action list-group-item-light nop"
//                       style={{ padding: "0.65rem", }}
//                     >
//                       <FiLogOut className="icon-width" />
//                       <span className="home_boom">Logout</span>
//                     </NavLink>
//                   </li>
//                 </ul>
//               </div>


//             </div>
//           </div>
//         </div>
//       </div>
//     </div>
//     <Redirect to={redirectTo} />
//     </>
//   }

//   return (
//     <div style={{ boxSizing: "border-box", width: "100%", height: "100vh", overflowY: "auto" }}>
//       <div className="d-flex p-10" id="wrapper" style={{ boxSizing: "border-box", width: "100%", maxWidth: "100%", height: "100%" }}>
//         <div className="bg-white w-100 d-flex flex-column h-100">
//           <div className="logo_text" style={{ width: "100%", overflow: "hidden", maxWidth: "100%" }}>
//             <NavLink to="/home" style={{ maxWidth: "100%" }}>
//               <img src={head_Logo} alt="" style={{ width: "100%" }} />{" "}
//             </NavLink>
//           </div>


//           <div className="list-group list-group-flush">
//             <div className="route-dom p-2">
//               <div className="overView">
//                 <div
//                   style={{
//                     paddingLeft: "11%",
//                     paddingRight: "5%",
//                     paddingBottom: "2%",
//                     borderRadius: 8,
//                     flexDirection: "column",
//                     justifyContent: "center",
//                     alignItems: "flex-start",
//                     display: "inline-flex",
//                   }}
//                 >
//                   <div
//                     style={{
//                       alignSelf: "stretch",
//                       color: "rgba(28, 28, 28, 0.40)",
//                       fontSize: 14,
//                       fontFamily: "Open Sans",
//                       fontWeight: "400",


//                       wordWrap: "break-word",
//                     }}
//                   >
//                     Dashboards
//                   </div>
//                 </div>
//                 <ul className="home_icon_img">
//                   {menuList?.slice(0, 1).map((data, index) => (
//                     <li key={index}>
//                       <NavLink
//                         activeClassName="active"
//                         to={`/${data?.url}`}
//                         className="list-group-item list-group-item-action list-group-item-light p-3 nop"
//                       >
//                         {activeURL === data?.url ? (
//                           <img
//                             src={`${config.BASE_URL}${data?.activeIcon}`}
//                             alt=""
//                           />
//                         ) : (
//                           <img
//                             src={`${config.BASE_URL}${data?.inactiveIcon}`}
//                             alt=""
//                           />
//                         )}

//                         <span className="home_boom">Overview</span>
//                       </NavLink>
//                     </li>
//                   ))}
//                 </ul>
//               </div>
//               <div className="pages" style={{ maxHeight: "calc(100vh - 200px)", overflowY: "auto" }}>
//                 <div
//                   style={{
//                     paddingLeft: "11%",
//                     paddingRight: "5%",
//                     paddingBottom: "2%",
//                     borderRadius: 8,
//                     flexDirection: "column",
//                     justifyContent: "center",
//                     alignItems: "flex-start",
//                     display: "inline-flex",
//                   }}
//                 >
//                   <div
//                     style={{
//                       alignSelf: "stretch",
//                       color: "rgba(28, 28, 28, 0.40)",
//                       fontSize: 14,
//                       fontFamily: "Open Sans",
//                       fontWeight: "400",
//                       wordWrap: "break-word",
//                     }}
//                   >
//                     Pages
//                   </div>
//                 </div>


//                 <ul className="home_icon_img">
//                   {menuList?.slice(1).map((data, index) => {
//                     if (data.caption === "Reporting Module") {
//                       return (
//                         <li key={index}>
//                           <NavLink
//                             onClick={toggleSubmenu}
//                             className="reporting-module
//                            list-group-item-action
//                             list-group-item-light 
                            
//                             nop 
//                             "
//                             onMouseEnter={(e) => e.currentTarget.style.cursor = 'pointer'}
//                             onMouseLeave={(e) => e.currentTarget.style.cursor = 'default'}
//                             to={{
//                               pathname: `/reporting-modules/${toUrlFriendlyName('all-module')}`,
//                               state: { moduleData: module['All Module'], assignedTo: assignedTo, sourceData: sourceData }
//                             }}
//                             style={{
//                               display: "flex",
//                               padding: "0.65rem",
//                               borderRadius: "10px",
//                               backgroundColor: isRotated ? '#3F88A5' : 'transparent',
//                               color: isRotated ? 'white' : 'inherit',
//                               transition: 'background-color 0.3s ease',
//                             }} >
//                             <img src={`${config.BASE_URL}${data?.inactiveIcon}`} alt="" />
//                             <span className="home_boom">{data.caption}</span>

//                             <div style={{
//                               marginLeft: '24%',
//                               marginTop: "2%",
//                               height: "10px", width: "20px",
//                               color: isRotated ? "white" : "#3F88A5",
//                               transform: isRotated ? 'rotate(180deg)' : 'rotate(0deg)',
//                               transition: 'transform 0.3s ease', // Smooth transition for the rotation
//                             }}>
//                               <svg width="19" height="10" viewBox="0 0 19 10" fill="currentcolor" xmlns="http://www.w3.org/2000/svg">
//                                 <path id="Vector" stroke="currentcolor" d="M8.73823 9.70349C9.0194 9.95308 9.33455 10 9.50032 10C9.68532 10 9.97862 9.9545 10.2614 9.70349L18.7262 1.54155C19.1137 1.16804 19.0866 0.58669 18.666 0.242975C18.245 -0.100919 17.5903 -0.0759709 17.2033 0.296641L9.5 7.72292L1.79695 0.296641C1.40913 -0.0770478 0.754206 -0.10074 0.334037 0.242975C-0.0865365 0.58669 -0.113631 1.16804 0.27358 1.54155L8.73823 9.70349Z" fill="#currentcolor" />
//                               </svg>


//                             </div>


//                           </NavLink>
//                           {/* Submenu */}
//                           {isSubmenuVisible && (
//                             <ul className="submenu" style={{ marginTop: "10px" }}>
//                               {Object.keys(module).map((moduleName, idx) => (
//                                 <li key={idx} className="submenu-item">
//                                   <NavLink
//                                     to={{
//                                       pathname: `/reporting-modules/${toUrlFriendlyName(moduleName)}`,
//                                       state: { moduleData: module[moduleName], assignedTo: assignedTo, sourceData: sourceData }
//                                     }}
//                                     className="list-group-item list-group-item-action list-group-item-light p-2 nop"
//                                     style={{ background: "#E2EAEC", borderRadius: "5px" }}
//                                   >
//                                     {moduleName}
//                                   </NavLink>
//                                 </li>
//                               ))}
//                             </ul>
//                           )}
//                         </li>
//                       );
//                     }

//                     if (data.caption === "Audit") {
//                       return (


//                         <li key={index}>
//                           <div
//                             onClick={toggleAuditSubmenu}
//                             className="audit-module list-group-item-action list-group-item-light  nop"
//                             onMouseEnter={(e) => (e.currentTarget.style.cursor = "pointer")}
//                             onMouseLeave={(e) => (e.currentTarget.style.cursor = "default")}
//                             style={{
//                               display: "flex",
//                               padding: "0.65rem",

//                               borderRadius: "10px",
//                               backgroundColor: isAuditRotated ? '#3F88A5' : 'transparent', // Conditionally set the background color
//                               color: isAuditRotated ? 'white' : 'inherit', // Optionally, change text color for better visibility
//                               transition: 'background-color 0.3s ease', // Smooth transition for background color change
//                             }}
//                           >
//                             <img src={`${config.BASE_URL}${data?.inactiveIcon}`} alt="" />
//                             <span className="home_boom">{data?.caption}</span>
//                             <div style={{
//                               marginLeft: '60%',
//                               marginTop: "1%",
//                               height: "10px", width: "20px",
//                               color: isAuditRotated ? "white" : "#3F88A5",
//                               transform: isAuditRotated ? 'rotate(180deg)' : 'rotate(0deg)',
//                               transition: 'transform 0.3s ease', // Smooth transition for the rotation
//                             }}>
//                               <svg width="19" height="10" viewBox="0 0 19 10" fill="currentcolor" xmlns="http://www.w3.org/2000/svg">
//                                 <path id="Vector" stroke="currentcolor" d="M8.73823 9.70349C9.0194 9.95308 9.33455 10 9.50032 10C9.68532 10 9.97862 9.9545 10.2614 9.70349L18.7262 1.54155C19.1137 1.16804 19.0866 0.58669 18.666 0.242975C18.245 -0.100919 17.5903 -0.0759709 17.2033 0.296641L9.5 7.72292L1.79695 0.296641C1.40913 -0.0770478 0.754206 -0.10074 0.334037 0.242975C-0.0865365 0.58669 -0.113631 1.16804 0.27358 1.54155L8.73823 9.70349Z" fill="#currentcolor" />
//                               </svg>


//                             </div>
//                           </div>
//                           {isAuditSubmenuVisible && (
//                             <ul className="submenu" style={{ marginTop: "10px" }}>
//                               <li className="submenu-item">
//                                 <div
//                                   onClick={handleClick}
//                                   to={{
//                                     pathname: `/audit-listing/${toUrlFriendlyName('all-module')}`,
//                                     state: { auditModuleData: auditModule["All Module"], auditAssignedTo: auditAssignedTo, sourceData: sourceData },
//                                   }}
//                                   className="list-group-item list-group-item-action list-group-item-light p-2 nop"
//                                   style={{
//                                     //  display: "flex", 
//                                     cursor: "pointer", border: "none ", background: "#E2EAEC", borderRadius: "5px"
//                                   }}
//                                 >
//                                   <div style={{ display: 'flex' }}>
//                                     <div>
//                                       Audit Listing

//                                     </div>
//                                     <div style={{
//                                       marginLeft: '43.5%',
//                                       marginTop: "2%",
//                                       height: "8px", width: "16px",
//                                       color: isAuditSubmenuRotated ? "black" : "black",
//                                       transform: isAuditSubmenuRotated ? 'rotate(180deg)' : 'rotate(0deg)',
//                                       transition: 'transform 0.3s ease', // Smooth transition for the rotation
//                                     }}>
//                                       <svg width="19" height="10" viewBox="0 0 19 10" fill="currentcolor" xmlns="http://www.w3.org/2000/svg">
//                                         <path id="Vector" stroke="currentcolor" d="M8.73823 9.70349C9.0194 9.95308 9.33455 10 9.50032 10C9.68532 10 9.97862 9.9545 10.2614 9.70349L18.7262 1.54155C19.1137 1.16804 19.0866 0.58669 18.666 0.242975C18.245 -0.100919 17.5903 -0.0759709 17.2033 0.296641L9.5 7.72292L1.79695 0.296641C1.40913 -0.0770478 0.754206 -0.10074 0.334037 0.242975C-0.0865365 0.58669 -0.113631 1.16804 0.27358 1.54155L8.73823 9.70349Z" fill="#currentcolor" />
//                                       </svg>


//                                     </div>

//                                   </div>



//                                 </div>
//                                 {isAuditListingSubmenuVisible && (
//                                   <ul className="submenu" style={{ marginTop: "10px", paddingLeft: "15px" }}>
//                                     {Object.keys(auditModule).map((moduleName, idx) => (
//                                       <li key={idx} className="submenu-item">
//                                         <NavLink
//                                           to={{
//                                             pathname: `/audit-listing/${toUrlFriendlyName(moduleName)}`,
//                                             state: { auditModuleData: auditModule[moduleName], auditAssignedTo: auditAssignedTo, sourceData: sourceData },
//                                           }}
//                                           className="list-group-item list-group-item-action list-group-item-light p-2 nop"
//                                           style={{ borderRadius: "10px" }}
//                                         >
//                                           {moduleName}
//                                         </NavLink>
//                                       </li>
//                                     ))}
//                                   </ul>
//                                 )}
//                               </li>
//                               <li className="submenu-item">
//                                 <NavLink
//                                   to="/audit-history"
//                                   className="list-group-item list-group-item-action list-group-item-light p-2 nop"
//                                   style={{ background: "#E2EAEC", borderRadius: "5px" }}
//                                 >
//                                   Audit History
//                                 </NavLink>
//                               </li>
//                             </ul>
//                           )}
//                         </li>

//                       )
//                     }





//                     // if (data.caption === "Audit") {
//                     //   return (
//                     //     <li key={index}>
//                     //       <div
//                     //         onClick={toggleAuditSubmenu}
//                     //         className="audit-module list-group-item-action list-group-item-light p-3 nop"
//                     //         onMouseEnter={(e) => (e.currentTarget.style.cursor = "pointer")}
//                     //         onMouseLeave={(e) => (e.currentTarget.style.cursor = "default")}
//                     //       >
//                     //         <img src={`${config.BASE_URL}${data?.inactiveIcon}`} alt="" />
//                     //         <span className="home_boom">{data.caption}</span>
//                     //       </div>
//                     //       {isAuditSubmenuVisible && (
//                     //         <ul className="submenu" style={{ marginTop: "10px" }}>
//                     //           <li className="submenu-item">
//                     //             <div
//                     //               onClick={toggleAuditListingSubmenu}
//                     //               className="list-group-item list-group-item-action list-group-item-light p-2 nop"
//                     //               style={{ cursor: "pointer", border: "none " }}
//                     //             >
//                     //               Audit Listing
//                     //               {isAuditListingSubmenuVisible && (
//                     //                 <ul className="submenu" style={{ marginTop: "10px", paddingLeft: "15px" }}>
//                     //                   {Object.keys(auditModule).map((moduleName, idx) => (
//                     //                     <li key={idx} className="submenu-item">
//                     //                       <NavLink
//                     //                         to={{
//                     //                           pathname: `/audit-listing/${toUrlFriendlyName(moduleName)}`,
//                     //                           state: { auditModuleData: auditModule[moduleName], auditAssignedTo: auditAssignedTo ,sourceData:sourceData},
//                     //                         }}
//                     //                         className="list-group-item list-group-item-action list-group-item-light p-2 nop"
//                     //                       >
//                     //                         {moduleName}
//                     //                       </NavLink>
//                     //                     </li>
//                     //                   ))}
//                     //                 </ul>
//                     //               )}
//                     //             </div>
//                     //           </li>
//                     //           <li className="submenu-item">
//                     //             <NavLink
//                     //               to="/audit-history"
//                     //               className="list-group-item list-group-item-action list-group-item-light p-2 nop"
//                     //             >
//                     //               Audit History
//                     //             </NavLink>
//                     //           </li>
//                     //         </ul>
//                     //       )}
//                     //     </li>
//                     //   );
//                     // }
//                     return (
//                       <li key={index}>
//                         <NavLink
//                           activeClassName="active"
//                           to={`/${data?.url}`}
//                           className="list-group-item list-group-item-action list-group-item-light nop"
//                           style={{
//                             padding: "0.65rem",
//                           }}
//                         >
//                           {activeURL === data?.url ? (
//                             <img src={`${config.BASE_URL}${data?.activeIcon}`} alt="" />
//                           ) : (
//                             <img src={`${config.BASE_URL}${data?.inactiveIcon}`} alt="" />
//                           )}
//                           <span className="home_boom">{data.caption}</span>
//                         </NavLink>
//                       </li>
//                     );
//                   })}
//                   <li>
//                     <NavLink
//                       to="/"
//                       onClick={logout}
//                       isActive={() => false}
//                       className="list-group-item list-group-item-action list-group-item-light nop"
//                       style={{ padding: "0.65rem", }}
//                     >
//                       <FiLogOut className="icon-width" />
//                       <span className="home_boom">Logout</span>
//                     </NavLink>
//                   </li>
//                 </ul>
//               </div>


//             </div>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default Sidebar;

import React, { useEffect, useState, createContext, useContext, } from "react";
import { NavLink, Link } from "react-router-dom";
import foot_Logo from "../../img/logol.png";
import head_Logo from "../../img/Riu_TM_Logo.png";
import { apiCall } from "../../_services/apiCall";
import "./common.css";
import "./sidebar.css";
import config from "../../config/config.json";
import { PermissionMenuContext } from "../../contextApi/permissionBasedMenuContext";
import { authenticationService } from "../../_services/authentication";
import { history } from "../../_helpers/history";
import { FiLogOut } from "react-icons/fi";
import { set } from "lodash";
import down from "../../img/DownArrow.svg";

export const AuditContext = createContext();
export const useAudit = () => useContext(AuditContext);

// export const ReportContext = createContext();
// export const useReport =()=>useContext(ReportContext)



export const AuditProvider = ({ children }) => {
  const [auditModuleNames, setAuditModuleNames] = useState([]);
  const [auditModule, setAuditModule] = useState([]);
  const [auditAssignedTo, setAuditAssignedTo] = useState([]);
  const [sourceData, setSourceData] = useState();

  const getSource = async () => {
    const { isSuccess, data } = await apiCall(
      `${config.POSTLOGIN_API_URL_COMPANY}getSource`,
      {},
      {},
      "GET"
    );
    if (isSuccess) {
      console.log("hihihhahaha", data.data)
      setSourceData(data?.data);
    }
  };

  useEffect(() => {
    getSource()
    getAuditListing()
    
   
  }, [])

  const getFinancialYear = async () => {
    const { isSuccess, data } = await apiCall(
      `${config.POSTLOGIN_API_URL_COMPANY}getFinancialYear`,
      {},
      {}
    );

    if (isSuccess) {

      return (data?.data[data.data.length - 1]?.id);

    }
  };


  const getAuditListing = async () => {
    try {
      const response = await apiCall(
        `${config.POSTLOGIN_API_URL_COMPANY}getAuditListing`,
        {},
        {
          financialYearId: await getFinancialYear(),
          // frameworkIds: [1] 
        },
        "GET"
      );
      if (response.isSuccess) {
        const data = response.data;
        //console.log("auditListing", data);

        // Set to collect unique module names
        const moduleNamesSet = new Set();

        // Object to group data by module
        const groupedData = data.data.reduce((acc, item) => {
          const moduleName = item.question?.moduleName || "Unknown Module";

          // Add module name to the set
          moduleNamesSet.add(moduleName);

          // Initialize array if not already done
          if (!acc["All Module"]) {
            acc["All Module"] = [];
          }
          acc["All Module"].push(item);
          if (!acc[moduleName]) {
            acc[moduleName] = [];
          }

          // Add item to the module's array
          acc[moduleName].push(item);
          return acc;
        }, {});

        const moduleNamesList = Array.from(moduleNamesSet);

        setAuditModule(groupedData);
        setAuditModuleNames(moduleNamesList);

        const assignedToData = data.getAssignedDetails

        setAuditAssignedTo(assignedToData);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  return (
    <AuditContext.Provider value={{ getAuditListing, auditModule, auditModuleNames, auditAssignedTo }}>
      {children}
    </AuditContext.Provider>
  );
};

// export const ReportingProvider = ({children})=>{

//   const [frameworkValue,setFrameworkValue]=useState();
//   const [moduleNames,setModuleNames]=useState();
//   const [module,setModule]=useState();
//   const [assignedTo,setAssignedTo]=useState()

//   const getFinancialYear = async () => {
//     const { isSuccess, data } = await apiCall(
//       `${config.POSTLOGIN_API_URL_COMPANY}getFinancialYear`,
//       {},
//       {}
//     );

//     if (isSuccess) {

//       return (data?.data[data.data.length - 1]?.id);

//     }
//   };

//   const fetchFrameworkApi = async () => {
//     const { isSuccess, data } = await apiCall(
//       `${config.POSTLOGIN_API_URL_COMPANY}getFramework`,
//       {},
//       { type: "ALL" }
//     );
//     if (isSuccess) {
//       const frameworkIds = data?.data.map((item) => item.id);
//       setFrameworkValue(frameworkIds);
//       return data?.data.map((item) => item.id);
//     }
//   };


//   const getReportingQuestions = async () => {
//     try {
//       const response = await apiCall(
//         `${config.POSTLOGIN_API_URL_COMPANY}getReportingQuestion`,
//         {},
//         {
//           financialYearId: await getFinancialYear(),
//           frameworkIds: await fetchFrameworkApi()
//         },
//         "GET"
//       );
//       if (response.isSuccess) {
//         const data = response.data;

//         const moduleNames = [...new Set(data.data.map(item => item.moduleName))];

//         setModuleNames(moduleNames);
//         const groupedByModuleName = data.data.reduce((acc, item) => {
//           if (!acc["All Module"]) {
//             acc["All Module"] = [];
//           }
//           acc["All Module"].push(item);
//           if (!acc[item.moduleName]) {
//             acc[item.moduleName] = [];
//           }
//           acc[item.moduleName].push(item);


//           return acc;
//         }, {});

//         setModule(groupedByModuleName);

//         const assignedToData = data.assignedDetail
//         setAssignedTo(assignedToData); // Assuming `setAssignedTo` is a state sette

//       }
//     } catch (error) {
//       console.error("Error fetching data:", error);
//     }
//   };

//   useEffect(() => {
//     getReportingQuestions()
    
   
//   }, [])

//   return(
// <ReportContext.Provider value={{ getReportingQuestions, module, moduleNames, assignedTo }}>
//       {children}
//     </ReportContext.Provider>
//   )
  
// }

const Sidebar = (props) => {
  const financeObjct = props?.financeObjct;
  const contextType = useContext(PermissionMenuContext);
  const [menuList, setMenuList] = useState([]);
  const [frameworkValue, setFrameworkValue] = useState([]);
  const [moduleNames, setModuleNames] = useState([]);
  const [module, setModule] = useState([]);
  const [isSubmenuVisible, setIsSubmenuVisible] = useState(false);
  const [assignedTo, setAssignedTo] = useState([]);
  const [isAuditSubmenuVisible, setIsAuditSubmenuVisible] = useState(false);
  const [isAuditListingSubmenuVisible, setIsAuditListingSubmenuVisible] = useState(false);
  const [auditModuleNames, setAuditModuleNames] = useState([]);
  const [auditModule, setAuditModule] = useState([]);
  const [auditAssignedTo, setAuditAssignedTo] = useState([]);
  const [sourceData, setSourceData] = useState();
  const [isRotated, setIsRotated] = useState(false);
  const [isAuditRotated, setIsAuditRotated] = useState(false);
  const [isAuditSubmenuRotated, setIsAuditSubmenuRotated] = useState(false)

  
  // useEffect(()=>{
  //   const storedIsSubmenuVisible = localStorage.getItem('isSubmenuVisible');
  //   if (storedIsSubmenuVisible !== null) {
  //     setIsSubmenuVisible(JSON.parse(storedIsSubmenuVisible));
  //   }

  // },[])



  const getAuditListing = async () => {
    try {
      const response = await apiCall(
        `${config.POSTLOGIN_API_URL_COMPANY}getAuditListing`,
        {},
        {
          financialYearId: await getFinancialYear(),
          // frameworkIds: [1] 
        },
        "GET"
      );
      if (response.isSuccess) {
        const data = response.data;
        //console.log("auditListing", data);

        // Set to collect unique module names
        const moduleNamesSet = new Set();

        // Object to group data by module
        const groupedData = data.data.reduce((acc, item) => {
          const moduleName = item.question?.moduleName || "Unknown Module";

          // Add module name to the set
          moduleNamesSet.add(moduleName);

          // Initialize array if not already done
          if (!acc["All Module"]) {
            acc["All Module"] = [];
          }
          acc["All Module"].push(item);
          if (!acc[moduleName]) {
            acc[moduleName] = [];
          }

          // Add item to the module's array
          acc[moduleName].push(item);
          return acc;
        }, {});

        const moduleNamesList = Array.from(moduleNamesSet);

        setAuditModule(groupedData);
        setAuditModuleNames(moduleNamesList);

        const assignedToData = data.getAssignedDetails

        setAuditAssignedTo(assignedToData);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const getSource = async () => {
    const { isSuccess, data } = await apiCall(
      `${config.POSTLOGIN_API_URL_COMPANY}getSource`,
      {},
      {},
      "GET"
    );
    if (isSuccess) {
      setSourceData(data?.data);
    }
  };



  // Function to toggle submenu visibility
  const toggleSubmenu = async () => {

     getSource()

    // Await the completion of getReportingQuestions
   await getReportingQuestions();

  localStorage.removeItem("reportingQuestion")

    const newIsSubmenuVisible = !isSubmenuVisible;
    // localStorage.setItem('isSubmenuVisible', JSON.stringify(newIsSubmenuVisible));

    // Update the submenu visibility state
    setIsSubmenuVisible(newIsSubmenuVisible);
    
    setIsRotated(!isRotated);

  };


  useEffect(()=>{
    const fetchData = async () => {
      try {
        await getReportingQuestions(); // Call your async function here
      } catch (error) {
        console.error("Error fetching reporting questions:", error);
      }
    };
  
    fetchData(); // Invoke the async function
  },[])
  const toggleAuditListingSubmenu = () => {
    getSource()
    setIsAuditListingSubmenuVisible(!isAuditListingSubmenuVisible);
    setIsAuditSubmenuRotated(!isAuditSubmenuRotated)
  };

  const toggleAuditSubmenu = () => {
    localStorage.removeItem("reportingQuestion")
    console.log("clicked")
    getAuditListing()
    setIsAuditSubmenuVisible(!isAuditSubmenuVisible);
    setIsAuditRotated(!isAuditRotated)
  };
  const fetchFrameworkApi = async () => {
    const { isSuccess, data } = await apiCall(
      `${config.POSTLOGIN_API_URL_COMPANY}getFramework`,
      {},
      { type: "ALL" }
    );
    if (isSuccess) {
      const frameworkIds = data?.data.map((item) => item.id);
      setFrameworkValue(frameworkIds);
      return data?.data.map((item) => item.id);
    }
  };

  const toUrlFriendlyName = (name) => {
    return name.toLowerCase().replace(/\s+/g, '-');
  };

  const getFinancialYear = async () => {
    const { isSuccess, data } = await apiCall(
      `${config.POSTLOGIN_API_URL_COMPANY}getFinancialYear`,
      {},
      {}
    );

    if (isSuccess) {

      return (data?.data[data.data.length - 1]?.id);

    }
  };


  const getReportingQuestions = async () => {
    try {
      const response = await apiCall(
        `${config.POSTLOGIN_API_URL_COMPANY}getReportingQuestion`,
        {},
        {
          financialYearId: await getFinancialYear(),
          frameworkIds: await fetchFrameworkApi()
        },
        "GET"
      );
      if (response.isSuccess) {
        const data = response.data;

        const moduleNames = [...new Set(data.data.map(item => item.moduleName))];

        setModuleNames(moduleNames);
        const groupedByModuleName = data.data.reduce((acc, item) => {
          if (!acc["All Module"]) {
            acc["All Module"] = [];
          }
          acc["All Module"].push(item);
          if (!acc[item.moduleName]) {
            acc[item.moduleName] = [];
          }
          acc[item.moduleName].push(item);


          return acc;
        }, {});

        setModule(groupedByModuleName);

        const assignedToData = data.assignedDetail
        setAssignedTo(assignedToData); // Assuming `setAssignedTo` is a state sette

      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };


  useEffect(() => {
    const settingsMenu = JSON.parse(localStorage.getItem("menu"));
    fetchFrameworkApi()
    // if (/^\/reporting-modules\/[^\/]+$/.test(window.location.pathname)) {
    // getReportingQuestions();
    // // }
    // if (/^\/audit-listing\/[^\/]+$/.test(window.location.pathname)) {

    // }
    setMenuList(settingsMenu);
    //console.log("menuList", menuList);
  }, [contextType.state]);

  const logout = () => {
    authenticationService.logout();
    history.push("/");
    localStorage.clear();
  };

  const currentURL = window.location.href;
  const splitURL = currentURL.split("/");
  const activeURL = splitURL[4];
  const { Defaults } = props;

  return (
    <div style={{ boxSizing: "border-box", width: "100%", height: "100vh", overflowY: "auto" }}>
      <div className="d-flex p-10" id="wrapper" style={{ boxSizing: "border-box", width: "100%", maxWidth: "100%", height: "100%" }}>
        <div className="bg-white w-100 d-flex flex-column h-100">
          <div className="logo_text" style={{ width: "100%", overflow: "hidden", maxWidth: "100%" }}>
            <NavLink to="/home" style={{ maxWidth: "100%" }}>
              <img src={head_Logo} alt="" style={{ width: "100%" }} />{" "}
            </NavLink>
          </div>


          <div className="list-group list-group-flush">
            <div className="route-dom p-2">
              <div className="overView">
                <div
                  style={{
                    paddingLeft: "11%",
                    paddingRight: "5%",
                    paddingBottom: "2%",
                    borderRadius: 8,
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "flex-start",
                    display: "inline-flex",
                  }}
                >
                  <div
                    style={{
                      alignSelf: "stretch",
                      color: "rgba(28, 28, 28, 0.40)",
                      fontSize: 14,
                      fontFamily: "Open Sans",
                      fontWeight: "400",


                      wordWrap: "break-word",
                    }}
                  >
                    Dashboards
                  </div>
                </div>
                <ul className="home_icon_img">
                  {menuList?.slice(0, 1).map((data, index) => (
                    <li key={index}>
                      <NavLink
                        activeClassName="active"
                        to={`/${data?.url}`}
                        className="list-group-item list-group-item-action list-group-item-light p-3 nop"
                      >
                        {activeURL === data?.url ? (
                          <img
                            src={`${config.BASE_URL}${data?.activeIcon}`}
                            alt=""
                          />
                        ) : (
                          <img
                            src={`${config.BASE_URL}${data?.inactiveIcon}`}
                            alt=""
                          />
                        )}

                        <span className="home_boom">Overview</span>
                      </NavLink>
                    </li>
                  ))}
                </ul>
              </div>
              <div className="pages" style={{ maxHeight: "calc(100vh - 200px)", overflowY: "auto" }}>
                <div
                  style={{
                    paddingLeft: "11%",
                    paddingRight: "5%",
                    paddingBottom: "2%",
                    borderRadius: 8,
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "flex-start",
                    display: "inline-flex",
                  }}
                >
                  <div
                    style={{
                      alignSelf: "stretch",
                      color: "rgba(28, 28, 28, 0.40)",
                      fontSize: 14,
                      fontFamily: "Open Sans",
                      fontWeight: "400",
                      wordWrap: "break-word",
                    }}
                  >
                    Pages
                  </div>
                </div>


                <ul className="home_icon_img">
                  {menuList?.slice(1).map((data, index) => {
                    if (data.caption === "Reporting Module") {
                      return (
                        <li key={index}>
                          <NavLink 
                          onClick={toggleSubmenu} 
                          className="reporting-module
                           list-group-item-action
                            list-group-item-light 
                            
                            nop 
                            "
                            onMouseEnter={(e) => e.currentTarget.style.cursor = 'pointer'} // Change cursor to pointer on hover
                            onMouseLeave={(e) => e.currentTarget.style.cursor = 'default'}
                            to={{
                              pathname: `/reporting-modules/${toUrlFriendlyName('all-module')}`,
                              state: { moduleData: module['All Module'], assignedTo: assignedTo, sourceData: sourceData }
                            }}
                            style={{
                              display: "flex",
                              padding:"0.65rem",

                              borderRadius: "10px",
                              backgroundColor: isRotated ? '#3F88A5' : 'transparent', // Conditionally set the background color
                              color: isRotated ? 'white' : 'inherit', // Optionally, change text color for better visibility
                              transition: 'background-color 0.3s ease', // Smooth transition for background color change
                            }} >
                              <img src={`${config.BASE_URL}${data?.inactiveIcon}`} alt="" />
                              <span className="home_boom">{data.caption}</span>

                            <div style={{
                              marginLeft: '24%',
                              marginTop: "2%",
                              height: "10px", width: "20px",
                              color: isRotated ? "white" : "#3F88A5",
                              transform: isRotated ? 'rotate(180deg)' : 'rotate(0deg)',
                              transition: 'transform 0.3s ease', // Smooth transition for the rotation
                            }}>
                              <svg width="19" height="10" viewBox="0 0 19 10" fill="currentcolor" xmlns="http://www.w3.org/2000/svg">
                                <path id="Vector" stroke="currentcolor" d="M8.73823 9.70349C9.0194 9.95308 9.33455 10 9.50032 10C9.68532 10 9.97862 9.9545 10.2614 9.70349L18.7262 1.54155C19.1137 1.16804 19.0866 0.58669 18.666 0.242975C18.245 -0.100919 17.5903 -0.0759709 17.2033 0.296641L9.5 7.72292L1.79695 0.296641C1.40913 -0.0770478 0.754206 -0.10074 0.334037 0.242975C-0.0865365 0.58669 -0.113631 1.16804 0.27358 1.54155L8.73823 9.70349Z" fill="#currentcolor" />
                              </svg>


                            </div>


                          </NavLink>
                          {/* Submenu */}
                          {isSubmenuVisible && (
                            <ul className="submenu" style={{ marginTop: "10px" }}>
                              {Object.keys(module).map((moduleName, idx) => (
                                <li key={idx} className="submenu-item">
                                  <NavLink
                                    to={{
                                      pathname: `/reporting-modules/${toUrlFriendlyName(moduleName)}`,
                                      state: { moduleData: module[moduleName], assignedTo: assignedTo, sourceData: sourceData }
                                    }}
                                    className="list-group-item list-group-item-action list-group-item-light p-2 nop"
                                    style={{ background: "#E2EAEC", borderRadius: "5px" }}
                                  >
                                    {moduleName}
                                  </NavLink>
                                </li>
                              ))}
                            </ul>
                          )}
                        </li>
                      );
                    }

                    if (data.caption === "Audit") {
                      return (


                        <li key={index}>
                          <div
                            onClick={toggleAuditSubmenu}
                            className="audit-module list-group-item-action list-group-item-light  nop"
                            onMouseEnter={(e) => (e.currentTarget.style.cursor = "pointer")}
                            onMouseLeave={(e) => (e.currentTarget.style.cursor = "default")}
                            style={{
                              display: "flex",
                              padding:"0.65rem",

                              borderRadius: "10px",
                              backgroundColor: isAuditRotated ? '#3F88A5' : 'transparent', // Conditionally set the background color
                              color: isAuditRotated ? 'white' : 'inherit', // Optionally, change text color for better visibility
                              transition: 'background-color 0.3s ease', // Smooth transition for background color change
                            }}
                          >
                            <img src={`${config.BASE_URL}${data?.inactiveIcon}`} alt="" />
                            <span className="home_boom">{data?.caption}</span>
                            <div style={{
                              marginLeft: '60%',
                              marginTop: "1%",
                              height: "10px", width: "20px",
                              color: isAuditRotated ? "white" : "#3F88A5",
                              transform: isAuditRotated ? 'rotate(180deg)' : 'rotate(0deg)',
                              transition: 'transform 0.3s ease', // Smooth transition for the rotation
                            }}>
                              <svg width="19" height="10" viewBox="0 0 19 10" fill="currentcolor" xmlns="http://www.w3.org/2000/svg">
                                <path id="Vector" stroke="currentcolor" d="M8.73823 9.70349C9.0194 9.95308 9.33455 10 9.50032 10C9.68532 10 9.97862 9.9545 10.2614 9.70349L18.7262 1.54155C19.1137 1.16804 19.0866 0.58669 18.666 0.242975C18.245 -0.100919 17.5903 -0.0759709 17.2033 0.296641L9.5 7.72292L1.79695 0.296641C1.40913 -0.0770478 0.754206 -0.10074 0.334037 0.242975C-0.0865365 0.58669 -0.113631 1.16804 0.27358 1.54155L8.73823 9.70349Z" fill="#currentcolor" />
                              </svg>


                            </div>
                          </div>
                          {isAuditSubmenuVisible && (
                            <ul className="submenu" style={{ marginTop: "10px" }}>
                              <li className="submenu-item">
                                <div
                                  onClick={toggleAuditListingSubmenu}
                                  className="list-group-item list-group-item-action list-group-item-light p-2 nop"
                                  style={{
                                    //  display: "flex", 
                                    cursor: "pointer", border: "none ", background: "#E2EAEC", borderRadius: "5px" }}
                                >
                                  <div style={{display:'flex'}}>
                                    <div>
                                    Audit Listing

                                    </div>
                                  <div style={{
                                    marginLeft: '43.5%',
                                    marginTop: "2%",
                                    height: "8px", width: "16px",
                                    color: isAuditSubmenuRotated ? "black" : "black",
                                    transform: isAuditSubmenuRotated ? 'rotate(180deg)' : 'rotate(0deg)',
                                    transition: 'transform 0.3s ease', // Smooth transition for the rotation
                                  }}>
                                    <svg width="19" height="10" viewBox="0 0 19 10" fill="currentcolor" xmlns="http://www.w3.org/2000/svg">
                                      <path id="Vector" stroke="currentcolor" d="M8.73823 9.70349C9.0194 9.95308 9.33455 10 9.50032 10C9.68532 10 9.97862 9.9545 10.2614 9.70349L18.7262 1.54155C19.1137 1.16804 19.0866 0.58669 18.666 0.242975C18.245 -0.100919 17.5903 -0.0759709 17.2033 0.296641L9.5 7.72292L1.79695 0.296641C1.40913 -0.0770478 0.754206 -0.10074 0.334037 0.242975C-0.0865365 0.58669 -0.113631 1.16804 0.27358 1.54155L8.73823 9.70349Z" fill="#currentcolor" />
                                    </svg>


                                  </div>

                                  </div>
                                  
                                  
                                 
                                </div>
                                {isAuditListingSubmenuVisible && (
                                    <ul className="submenu" style={{ marginTop: "10px", paddingLeft: "15px" }}>
                                      {Object.keys(auditModule).map((moduleName, idx) => (
                                        <li key={idx} className="submenu-item">
                                          <NavLink
                                            to={{
                                              pathname: `/audit-listing/${toUrlFriendlyName(moduleName)}`,
                                              state: { auditModuleData: auditModule[moduleName], auditAssignedTo: auditAssignedTo, sourceData: sourceData },
                                            }}
                                            className="list-group-item list-group-item-action list-group-item-light p-2 nop"
                                            style={{borderRadius:"10px"}}
                                          >
                                            {moduleName}
                                          </NavLink>
                                        </li>
                                      ))}
                                    </ul>
                                  )}
                              </li>
                              <li className="submenu-item">
                                <NavLink
                                  to="/audit-history"
                                  className="list-group-item list-group-item-action list-group-item-light p-2 nop"
                                  style={{ background: "#E2EAEC", borderRadius: "5px" }}
                                >
                                  Audit History
                                </NavLink>
                              </li>
                            </ul>
                          )}
                        </li>

                      )
                    }





                    // if (data.caption === "Audit") {
                    //   return (
                    //     <li key={index}>
                    //       <div
                    //         onClick={toggleAuditSubmenu}
                    //         className="audit-module list-group-item-action list-group-item-light p-3 nop"
                    //         onMouseEnter={(e) => (e.currentTarget.style.cursor = "pointer")}
                    //         onMouseLeave={(e) => (e.currentTarget.style.cursor = "default")}
                    //       >
                    //         <img src={`${config.BASE_URL}${data?.inactiveIcon}`} alt="" />
                    //         <span className="home_boom">{data.caption}</span>
                    //       </div>
                    //       {isAuditSubmenuVisible && (
                    //         <ul className="submenu" style={{ marginTop: "10px" }}>
                    //           <li className="submenu-item">
                    //             <div
                    //               onClick={toggleAuditListingSubmenu}
                    //               className="list-group-item list-group-item-action list-group-item-light p-2 nop"
                    //               style={{ cursor: "pointer", border: "none " }}
                    //             >
                    //               Audit Listing
                    //               {isAuditListingSubmenuVisible && (
                    //                 <ul className="submenu" style={{ marginTop: "10px", paddingLeft: "15px" }}>
                    //                   {Object.keys(auditModule).map((moduleName, idx) => (
                    //                     <li key={idx} className="submenu-item">
                    //                       <NavLink
                    //                         to={{
                    //                           pathname: `/audit-listing/${toUrlFriendlyName(moduleName)}`,
                    //                           state: { auditModuleData: auditModule[moduleName], auditAssignedTo: auditAssignedTo ,sourceData:sourceData},
                    //                         }}
                    //                         className="list-group-item list-group-item-action list-group-item-light p-2 nop"
                    //                       >
                    //                         {moduleName}
                    //                       </NavLink>
                    //                     </li>
                    //                   ))}
                    //                 </ul>
                    //               )}
                    //             </div>
                    //           </li>
                    //           <li className="submenu-item">
                    //             <NavLink
                    //               to="/audit-history"
                    //               className="list-group-item list-group-item-action list-group-item-light p-2 nop"
                    //             >
                    //               Audit History
                    //             </NavLink>
                    //           </li>
                    //         </ul>
                    //       )}
                    //     </li>
                    //   );
                    // }
                    return (
                      <li key={index}>
                        <NavLink
                          activeClassName="active"
                          to={`/${data?.url}`}
                          className="list-group-item list-group-item-action list-group-item-light nop"
                          style={{
                            padding:"0.65rem",
                          }}
                        >
                          {activeURL === data?.url ? (
                            <img src={`${config.BASE_URL}${data?.activeIcon}`} alt="" />
                          ) : (
                            <img src={`${config.BASE_URL}${data?.inactiveIcon}`} alt="" />
                          )}
                          <span className="home_boom">{data.caption}</span>
                        </NavLink>
                      </li>
                    );
                  })}
                  <li>
                    <NavLink
                      to="/"
                      onClick={logout}
                      isActive={() => false}
                      className="list-group-item list-group-item-action list-group-item-light nop"
                      style={{ padding:"0.65rem",}}
                    >
                      <FiLogOut className="icon-width" />
                      <span className="home_boom">Logout</span>
                    </NavLink>
                  </li>
                </ul>
              </div>


            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
