import React, { useState } from 'react';
// import './TotalWaterWithdraw.css'; // Import your CSS file here
import right from "../../img/Vector 1.svg"

const TotalEmplInclDifSingle = () => {
  const [isPermanent, setIsPermanent] = useState(true);

  // Data for permanent and non-permanent
  const permanentData = {
    consumption: 500,
    notGenerated: 100,
    maxConsumption: 1000,
  };

  const nonPermanentData = {
    consumption: 300,
    notGenerated: 200,
    maxConsumption: 1000,
  };

  // Choose the data based on the toggle state
  const { consumption, notGenerated, maxConsumption } = isPermanent ? permanentData : nonPermanentData;

  // Calculate the width percentages for each section of the bar
  const totalWidth = 100; // 100% width of the bar
  const filledWidth = (consumption / maxConsumption) * totalWidth;
  const notGeneratedWidth = (notGenerated / maxConsumption) * totalWidth;
  const remainingWidth = totalWidth - (filledWidth + notGeneratedWidth);

  const toggleData = () => {
    setIsPermanent(!isPermanent);
  };

  return (
    <div className="water-withdrawn-container">
      <div className="water-withdrawn-header">
        Total Employees Including Differently Abled
      </div>

      <div className="toggle-switch-container">
          <div className="toggle-switch" onClick={toggleData}>
            <div className={`toggle-knob ${isPermanent ? "on" : "off"}`}>
              <span
                style={{ fontSize: "30px", marginBottom: "25%" }}
                className="toggle-arrow"
              >
                {<img
  src={right}
  style={{ transform: isPermanent ? "rotate(0deg)" : "rotate(180deg)" }}
  alt="Arrow"
/> }
              </span>
            </div>
          </div>
          <p style={{ fontSize: "10px" }}>
            {isPermanent ? "Permanent" : "Non-Permanent"}
          </p>
        </div>

      <div className="water-withdrawn-bar-labels">
        <span style={{ fontSize: "11px" }}>0</span>
        <span style={{ fontSize: "11px" }}>{maxConsumption / 5}</span>
        <span style={{ fontSize: "11px" }}>{(maxConsumption / 5) * 2}</span>
        <span style={{ fontSize: "11px" }}>{(maxConsumption / 5) * 3}</span>
        <span style={{ fontSize: "11px" }}>{(maxConsumption / 5) * 4}</span>
        <span style={{ fontSize: "11px" }}>{maxConsumption}</span>
      </div>
      <div className="water-withdrawn-bar-dotted-line"></div>

      <div className="water-withdrawn-bars">
        <div className="water-withdrawn-bar" style={{ marginBottom: "2%" }}>
          <div
            className="water-withdrawn-bar-filled"
            style={{ width: `${filledWidth}%`, backgroundColor: 'green' }}
          />
        </div>
        <div className="water-withdrawn-bar" style={{ marginBottom: "2%" }}>
          <div
            className="water-withdrawn-bar-not-generated"
            style={{ width: `${notGeneratedWidth}%`, backgroundColor: 'orange' }}
          />
        </div>
        <div className="water-withdrawn-bar" style={{ marginBottom: "2%" }}>
          <div
            className="water-withdrawn-bar-remaining"
            style={{ width: `${remainingWidth}%`, backgroundColor: 'lightgray' }}
          />
        </div>
      </div>
    </div>
  );
};

export default TotalEmplInclDifSingle;
