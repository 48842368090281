import React from "react";
import { apiCall } from "../../_services/apiCall";
import config from "../../config/config.json";
import due from "../../img/Due.svg";
import updated from "../../img/updated.svg";
import done from "../../img/shape.svg";
import defaulted from "../../img/Defaulted.svg";
import { useState } from "react";
import { useEffect } from "react";
import TopComponentTraining from "./TopComponentTraining";
import HealthAndSafety from "./HealthAndSafety";
import ComparisonOfHealthAndSafety from "./ComparisonOfHealthAndSafetyMeasures";
import SkillUpgradationTraining from "./SkillUpgradationTraing";
import ComparisonOfSkillUpgradationTraining from "./ComparisonOfSkillUpgradationTraining";
import PerformanceReviewChart from "./PerformanceReviewChart";
import HumanRightsTraining from "./HumanRightsTraining";
import HealthSafetyMeasuresTraining from "./HealthAndSafetyMeasures";
import SafetyTrainingCompletionRate from "./SafetyTrainingCompletionRate";
import ComparisonOfSkillTraining from "./ComparisonOfSkillTraining";
import DetailsOfPerfomance from "./DetailsOfPerformance";
import LeadershipAndMentor from "./LeadershipAndMentor";
import HumanRightsTrainingBarChart from "./HumanRightsTrainingBarChart";

const Training = ({
  locationOption,
  timePeriods,
  financialYearId,
  graphData,
  frameworkValue,
  horizontal,
  type,
}) => {
  const location = 2;
  const [lastWeekAcitivities, setLastWeekAcitivities] = useState();
  const icons = {
    done: done,
    updated: updated,
    due: due,
    pending: defaulted,
  };

  const lastWeekActivity = async () => {
    const { isSuccess, data } = await apiCall(
      `${config.POSTLOGIN_API_URL_COMPANY}lastWeekActivity`,
      {},
      {},
      "GET"
    );
    if (isSuccess) {
      setLastWeekAcitivities(data?.data);
    }
  };

  useEffect(() => {
    lastWeekActivity();
  }, []);
  return (
    <div className="progress-container">
      {/* <div className="topcompo">
        {lastWeekAcitivities && (
          <TopComponentTraining
            lastWeekAcitivities={lastWeekAcitivities}
            icons={icons}
          />
        )}
      </div> */}

      {location === 1 && (
        <div className="d-flex flex-column flex-space-between">
          <div
            className="d-flex flex-row flex-space-between"
            style={{ height: "70vh", marginBottom: "3%" }}
          >
            <HealthAndSafety />
          </div>
          <div
            className="d-flex flex-row flex-space-between"
            style={{ height: "70vh", marginBottom: "3%" }}
          >
            <ComparisonOfHealthAndSafety />
          </div>
          <div
            className="d-flex flex-row flex-space-between"
            style={{ height: "70vh", marginBottom: "3%" }}
          >
            <div className="firsthalfprogressenergy">
              <SkillUpgradationTraining />
            </div>
            <div className="secondhalfprogress">
              <ComparisonOfSkillUpgradationTraining />
            </div>
          </div>
          <div
            className="d-flex flex-row flex-space-between"
            style={{ height: "70vh", marginBottom: "3%" }}
          >
            <div className="firsthalfprogressenergy">
              <SkillUpgradationTraining />
            </div>
            <div className="secondhalfprogress">
              <PerformanceReviewChart />
            </div>
          </div>
          <div
            className="d-flex flex-row flex-space-between"
            style={{ height: "70vh", marginBottom: "3%" }}
          >
            <HumanRightsTraining />
          </div>
        </div>
      )}
      {location >= 2 && (
        <div className="d-flex flex-column flex-space-between">
          <div
            className="d-flex flex-row flex-space-between"
            style={{ height: "70vh", marginBottom: "3%" }}
          >
            {/* <HealthSafetyMeasuresTraining /> */}
            <div className="firsthalfprogressenergy" style={{height:"100%"}}>
              <ComparisonOfSkillTraining
                locationOption={locationOption}
                timePeriods={timePeriods}
                financialYearId={financialYearId}
                graphData={graphData}
                frameworkValue={frameworkValue}
                type = 'EMPLOYEE'
                horizontal={false}
                forGraph = 'H&S'
              />
            </div>
            <div className="secondhalfprogress" style={{height:"100%"}}>
              <ComparisonOfSkillTraining
                locationOption={locationOption}
                timePeriods={timePeriods}
                financialYearId={financialYearId}
                graphData={graphData}
                frameworkValue={frameworkValue}
                type = 'WORKERS'
                horizontal={true}
                forGraph = 'H&S'
              />
            </div>
          </div>
          {/* <div
            className="d-flex flex-row flex-space-between"
            style={{ height: "70vh", marginBottom: "3%" }}
          > */}
            {/* <ComparisonOfHealthAndSafety
              locationOption={locationOption}
              timePeriods={timePeriods}
              financialYearId={financialYearId}
              graphData={graphData}
              frameworkValue={frameworkValue}
            /> */}
          {/* </div> */}
          <div
            className="d-flex flex-row flex-space-between"
            style={{ height: "70vh", marginBottom: "3%" }}
          >
            <div className="firsthalfprogressenergy" style={{height:"100%"}}>
              {/* <SafetyTrainingCompletionRate />
               */}
                  <ComparisonOfSkillTraining
                locationOption={locationOption}
                timePeriods={timePeriods}
                financialYearId={financialYearId}
                graphData={graphData}
                frameworkValue={frameworkValue}
                type = 'EMPLOYEE'
                horizontal={false}
                forGraph = 'S&U'
              />
            </div>
            <div className="secondhalfprogress">
              <ComparisonOfSkillTraining
                locationOption={locationOption}
                timePeriods={timePeriods}
                financialYearId={financialYearId}
                graphData={graphData}
                frameworkValue={frameworkValue}
                type = 'WORKERS'
                horizontal={true}
                forGraph = 'S&U'
              />
            </div>
          </div>
          {/* <div
            className="d-flex flex-row flex-space-between"
            style={{ height: "70vh", marginBottom: "3%" }}
          >
            <div className="firsthalfprogressenergy">
              <DetailsOfPerfomance />
            </div>
            <div className="secondhalfprogress">
              <LeadershipAndMentor />
            </div>
          </div> */}
          <div
            className="d-flex flex-row flex-space-between"
            style={{ height: "70vh", marginBottom: "3%" }}
          >
            <HumanRightsTraining
              locationOption={locationOption}
              timePeriods={timePeriods}
              financialYearId={financialYearId}
              graphData={graphData}
              frameworkValue={frameworkValue}
            />
          </div>
          {/* <div
            className="d-flex flex-row flex-space-between"
            style={{ height: "70vh", marginBottom: "3%" }}
          >
            <HumanRightsTrainingBarChart />
          </div> */}
        </div>
      )}
    </div>
  );
};

export default Training;
