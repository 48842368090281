import React from 'react';
import Chart from 'react-apexcharts';
import './DonutChart.css';

const DonutChart = ({ percentageConsumed, percentageNotConsumed, title, color }) => {
    const chartOptions = {
        chart: {
            type: 'donut',
        },
        plotOptions: {
            pie: {
                donut: {
                    size: '65%',
                    labels: {
                        show: true,
                        name: {
                            show: false, // Hide the name label
                        },
                        value: {
                            show: true,
                            fontSize: '24px',
                            fontWeight: 'bold',
                            color: '#333',
                            formatter: () => `${percentageConsumed}%`, // Display the consumed percentage
                        },
                        total: {
                            show: true,
                            showAlways: true,
                            label: '', // Empty label to avoid showing anything extra
                            fontSize: '24px',
                            fontWeight: 'bold',
                            color: '#333',
                            formatter: () => `${percentageConsumed}%`, // Center the consumed percentage
                        },
                    },
                },
            },
        },
        stroke: {
            show: false, // Remove stroke to keep the chart clean
        },
        tooltip: {
            enabled: false, // Disable tooltip if not needed
        },
        dataLabels: {
            enabled: false, // Disable the default data labels
        },
        legend: {
            show: false, // Hide the legend
        },
        responsive: [
            {
                breakpoint: 480,
                options: {
                    chart: {
                        width: 200,
                    },
                },
            },
        ],
        colors: [color || "#2a6478", "#e6f2f8"], // Set the colors for consumed and not consumed
    };

    const chartSeries = [percentageConsumed, percentageNotConsumed];

    return (
        <div className="donut-chart-container">
            <div className="donut-chart-title" style={{ height: "10%" }}>
                {title}
            </div>
            <div style={{ display: "flex", alignContent: 'center', justifyItems: "center", justifyContent: "center", height: "90%" }}>
                <Chart
                    options={chartOptions}
                    series={chartSeries}
                    type="donut"
                    height={"100%"}
                />
            </div>
        </div>
    );
};

export default DonutChart;
