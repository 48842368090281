import React, { useEffect } from "react";
import TopComponentEnergy from "./TopComponentEnergy";
import { apiCall } from "../../_services/apiCall";
import config from "../../config/config.json";
import due from "../../img/Due.svg";
import updated from "../../img/updated.svg";
import done from "../../img/shape.svg";
import defaulted from "../../img/Defaulted.svg";
import { useState } from "react";
import TotalEnergyConsumption from "./TotalEnergyConsumption";
import "./energy.css";
import ProductEnergyConsumption from "./ProductEnergyConsumption";
import LocationEnergyConsumption from "./LocationEnergyConsumption";
import TimeBasedProductMix from "./TimeBasedProductMix";
import LocationWise from "./LocationWise";
import OverTheYear from "./OverTheYear";
import ProductWiseEnergyConsumption from "./ProductWiseEnergyConsumption";
import EnergyIntensity from "./EnergyIntensity";
import TotalProductWise from "../Company Sub Admin/Pages/home/TotalProductWise";
import EnergyConsumptionBar from "../Environment/EnergyConsumptionBar";
import ProductWiseSingleLoc from "./ProductWiseSingleLoc";
import EnergyIntensitySingleLoc from "./EnergyIntensitySingleLoc";
import EnergyEfficiencyImprovement from "./EnergyEffImprove";
import Renewable from "../Environment/RenewableAndNoneRenew";
import DonutChart from "../Environment/DonutChart";
import OnSiteSingleLoc from "./OnSiteSingleLoc";
import GreenEnergyPurchaseSingle from "./GreenEnergyPurchaseSingle";

const Energy = ({
  locationOption,
  timePeriods,
  financialYearId,
  graphData,
  frameworkValue,
  sectorQuestionAnswerDataForGraph,
}) => {
  console.log(
    JSON.parse(sectorQuestionAnswerDataForGraph[1]["answer"]),
    "fdghjkiouytdstryui"
  );
  const location = 2;
  const [lastWeekAcitivities, setLastWeekAcitivities] = useState();
  const [companyFramework, setCompanyFramework] = useState([]);

  const icons = {
    0: done,
    1: updated,
    2: due,
    3: defaulted,
  };

  const lastWeekActivity = async () => {
    const { isSuccess, data } = await apiCall(
      `${config.POSTLOGIN_API_URL_COMPANY}lastWeekActivity`,
      {},
      {},
      "GET"
    );
    if (isSuccess) {
      const data = {
        "Total Energy": {
          number: "12.57 M",
          questionId: [],
        },
        "Renewable Energy": {
          number: "2.34 M",
          questionId: [],
        },
        "Non Renewable Energy": {
          number: "10.23 M",
          questionId: [],
        },

        message: "Good Evening, Sunil Kumar",
      };
      setLastWeekAcitivities(data);
    }
  };

  useEffect(() => {
    lastWeekActivity();
  }, []);

  useEffect(() => {
    if (frameworkValue && frameworkValue.length) {
      const frameworkId = frameworkValue.map((value) => value.id);
      setCompanyFramework(frameworkId);
    }
  }, [frameworkValue]);

  return (
    <div className="progress-container">
      <div className="topcompo">
        {lastWeekAcitivities && (
          <TopComponentEnergy
            lastWeekAcitivities={lastWeekAcitivities}
            icons={icons}
          />
        )}
      </div>
      {locationOption.length === 1 && (
        <div className="d-flex flex-column flex-space-between">
           <div
            className="d-flex flex-row flex-space-between"
            style={{ height: "25vh", marginBottom: "3%" }}
          >
            <EnergyConsumptionBar />
          </div>
          <div
            className="d-flex flex-row flex-space-between"
            style={{ height: "70vh", marginBottom: "3%" }}
          >
            <div className="firsthalfprogressenergy">
              {/* <TotalEnergyConsumption numLocations={location} /> */}
              <ProductWiseSingleLoc/>
            </div>
            <div className="secondhalfprogress">
              <div style={{height:"30%"}}>
                <EnergyIntensitySingleLoc/>

              </div>
              <div style={{height:"70%"}}>
                <EnergyEfficiencyImprovement/>
                
              </div>
             
            </div>
          </div>
          <div
            className="d-flex flex-row flex-space-between"
            style={{ height: "25vh", marginBottom: "3%" }}
          >
            <Renewable consumption={5} notGenerated={20} maxConsumption={30} />
          </div>

          <div
            className="d-flex flex-row flex-space-between"
            style={{ height: "70vh", marginBottom: "3%" }}
          >
            <div className="secondhalfprogress" style={{width:"30%"}}>
            <DonutChart
                  percentageConsumed={60}
                  percentageNotConsumed={40}
                  title="Energy Savings Overview"
                  color="#4cc200" // Custom color for the chart
                />
              {/* <LocationEnergyConsumption /> */}
            </div>
            <div className="firsthalfprogressenergy" style={{width:"70%"}}>
              <OnSiteSingleLoc title={"On Site Renewable Energy Generation"}/>
            </div>
          </div>
          <div
            className="d-flex flex-row flex-space-between"
            style={{ height: "30vh", marginBottom: "3%" }}
          >
           <GreenEnergyPurchaseSingle  consumption={5} notGenerated={20} maxConsumption={30}/>
          </div>
        </div>
      )}
      {locationOption.length >= 2 &&
        (companyFramework &&
        companyFramework.length &&
        companyFramework.includes(1) ? (
          <>
            <div
              className="d-flex flex-row flex-space-between"
              style={{ height: "70vh", marginBottom: "3%" }}
            >
              <div className="firsthalfprogressenergy">
                <TotalProductWise
                  key={"Total energy consumed"}
                  category={"Mix Of Energy Consumptions"}
                  data={{
                    categories: ["Renewable sources", "Non-Renewable sources"],
                    series: [
                      Number(
                        JSON.parse(
                          sectorQuestionAnswerDataForGraph[1]["answer"]
                        )[4][0]
                      ) || 0,
                      Number(
                        JSON.parse(
                          sectorQuestionAnswerDataForGraph[1]["answer"]
                        )[9][0]
                      ) || 0,
                    ],
                    direct: true,
                    filter: false,
                    unit: "GJ",
                    color: ["#228B22", "#32CD32"],
                    questionIds: [25],
                  }}
                  type="PRODUCTWISEPIECHART"
                />
              </div>
              <div className="secondhalfprogress">
                <TotalProductWise
                  key={"Compare energy consumed"}
                  category={"Compare energy consumed"}
                  data={{
                    categories: ["0", "2023-2024", "2022-2023"],
                    series: [
                      {
                        name: "Mix Of Energy Consumptions",
                        type: "column",
                        data: [
                          0,
                          Number(
                            JSON.parse(
                              sectorQuestionAnswerDataForGraph[1]["answer"]
                            )[10][0]
                          ),
                          Number(
                            JSON.parse(
                              sectorQuestionAnswerDataForGraph[1]["answer"]
                            )[10][1]
                          ),
                        ],
                      },
                      {
                        name: "Energy consumed from renewable sources",
                        type: "line",
                        data: [
                          0,
                          JSON.parse(
                            sectorQuestionAnswerDataForGraph[1]["answer"]
                          )[4][0] || 0,
                          JSON.parse(
                            sectorQuestionAnswerDataForGraph[1]["answer"]
                          )[4][1] || 0,
                        ],
                      },
                      {
                        name: "Energy consumed from non-renewable sources",
                        type: "line",
                        data: [
                          0,
                          JSON.parse(
                            sectorQuestionAnswerDataForGraph[1]["answer"]
                          )[9][0] || 0,
                          JSON.parse(
                            sectorQuestionAnswerDataForGraph[1]["answer"]
                          )[9][1] || 0,
                        ],
                      },
                    ],
                    direct: true,
                    graphType: "line",
                    filter: false,
                    unit: "GJ",
                    color: ["#99ff99", "#228B22", "#32CD32"],
                    questionIds: [25],
                  }}
                  type="PRODUCTWISEPIECHART"
                  size="430"
                />
              </div>
            </div>
            <div
              className="d-flex flex-row flex-space-between"
              style={{ height: "70vh", marginBottom: "3%" }}
            >
              <div className="firsthalfprogressenergy">
                <TotalProductWise
                  key={"Total energy consumed"}
                  category={"Sources of Renewable Energy"}
                  data={{
                    categories: [
                      "Electricity consumption",
                      "Fuel consumption",
                      "Energy consumption through other sources",
                    ],
                    series: [
                      Number(
                        JSON.parse(
                          sectorQuestionAnswerDataForGraph[1]["answer"]
                        )[1][0]
                      ) || 0,
                      Number(
                        JSON.parse(
                          sectorQuestionAnswerDataForGraph[1]["answer"]
                        )[2][0]
                      ) || 0,
                      Number(
                        JSON.parse(
                          sectorQuestionAnswerDataForGraph[1]["answer"]
                        )[3][0]
                      ) || 0,
                    ],
                    direct: true,
                    filter: false,
                    unit: "GJ",
                    color: ["#00802b", "#66cc99", "#1aff66"],
                    questionIds: [25],
                  }}
                  type="PRODUCTWISEPIECHART"
                />
              </div>
              <div className="secondhalfprogress">
                <TotalProductWise
                  key={"Total energy consumed"}
                  category={"Sources of Non-Renewable Energy"}
                  data={{
                    categories: [
                      "Electricity consumption",
                      "Fuel consumption",
                      "Energy consumption through other sources",
                    ],
                    series: [
                      Number(
                        JSON.parse(
                          sectorQuestionAnswerDataForGraph[1]["answer"]
                        )[6][0]
                      ) || 0,
                      Number(
                        JSON.parse(
                          sectorQuestionAnswerDataForGraph[1]["answer"]
                        )[7][0]
                      ) || 0,
                      Number(
                        JSON.parse(
                          sectorQuestionAnswerDataForGraph[1]["answer"]
                        )[8][0]
                      ) || 0,
                    ],
                    direct: true,
                    filter: false,
                    unit: "GJ",
                    color: ["#00802b", "#66cc99", "#1aff66"],
                    questionIds: [25],
                  }}
                  type="PRODUCTWISEPIECHART"
                />
              </div>
            </div>
            <div
              className="d-flex flex-row flex-space-between"
              style={{ height: "70vh", marginBottom: "3%" }}
            >
              <div className="firsthalfprogressenergy">
                <TotalProductWise
                  key={"Compare energy consumed"}
                  category={"Renewable Energy Comparison"}
                  data={{
                    categories: ["0", "2023-2024", "2022-2023"],
                    series: [
                      {
                        name: "Electricity consumption",
                        type: "line",
                        data: [
                          0,
                          JSON.parse(
                            sectorQuestionAnswerDataForGraph[1]["answer"]
                          )[1][0] || 0,
                          JSON.parse(
                            sectorQuestionAnswerDataForGraph[1]["answer"]
                          )[1][1] || 0,
                        ],
                      },
                      {
                        name: "Fuel consumption",
                        type: "line",
                        data: [
                          0,
                          JSON.parse(
                            sectorQuestionAnswerDataForGraph[1]["answer"]
                          )[2][0] || 0,
                          JSON.parse(
                            sectorQuestionAnswerDataForGraph[1]["answer"]
                          )[2][1] || 0,
                        ],
                      },
                      {
                        name: "Energy consumption through other sources",
                        type: "line",
                        data: [
                          0,
                          JSON.parse(
                            sectorQuestionAnswerDataForGraph[1]["answer"]
                          )[3][0] || 0,
                          JSON.parse(
                            sectorQuestionAnswerDataForGraph[1]["answer"]
                          )[3][1] || 0,
                        ],
                      },
                    ],
                    direct: true,
                    graphType: "line",
                    filter: false,
                    unit: "GJ",
                    color: ["#00802b", "#66cc99", "#1aff66"],
                    questionIds: [25],
                  }}
                  type="PRODUCTWISEPIECHART"
                  size="430"
                />
              </div>
              <div className="secondhalfprogress">
                <TotalProductWise
                  key={"Compare energy consumed"}
                  category={"Non-Renewable Energy Comparison"}
                  data={{
                    categories: ["0", "2023-2024", "2022-2023"],
                    series: [
                      {
                        name: "Electricity consumption",
                        type: "line",
                        data: [
                          0,
                          JSON.parse(
                            sectorQuestionAnswerDataForGraph[1]["answer"]
                          )[6][0] || 0,
                          JSON.parse(
                            sectorQuestionAnswerDataForGraph[1]["answer"]
                          )[6][1] || 0,
                        ],
                      },
                      {
                        name: "Fuel consumption",
                        type: "line",
                        data: [
                          0,
                          JSON.parse(
                            sectorQuestionAnswerDataForGraph[1]["answer"]
                          )[7][0] || 0,
                          JSON.parse(
                            sectorQuestionAnswerDataForGraph[1]["answer"]
                          )[7][1] || 0,
                        ],
                      },
                      {
                        name: "Energy consumption through other sources",
                        type: "line",
                        data: [
                          0,
                          JSON.parse(
                            sectorQuestionAnswerDataForGraph[1]["answer"]
                          )[8][0] || 0,
                          JSON.parse(
                            sectorQuestionAnswerDataForGraph[1]["answer"]
                          )[8][1] || 0,
                        ],
                      },
                    ],
                    direct: true,
                    graphType: "line",
                    filter: false,
                    unit: "GJ",
                    color: ["#00802b", "#66cc99", "#1aff66"],
                    questionIds: [26],
                  }}
                  type="PRODUCTWISEPIECHART"
                  size="430"
                />
              </div>
            </div>
          </>
        ) : (
          <div className="d-flex flex-column flex-space-between">
            <div
              className="d-flex flex-row flex-space-between"
              style={{ height: "70vh", marginBottom: "3%", width: "76vw" }}
            >
              <ProductWiseEnergyConsumption
                locationOption={locationOption}
                timePeriods={timePeriods}
                financialYearId={financialYearId}
                graphData={graphData}
              />
            </div>
            <div
              className="d-flex flex-row flex-space-between"
              style={{ height: "70vh", marginBottom: "3%", width: "76vw" }}
            >
              <EnergyIntensity
                locationOption={locationOption}
                timePeriods={timePeriods}
                financialYearId={financialYearId}
                graphData={graphData}
              />
            </div>
          </div>
        ))}
    </div>
  );
};

export default Energy;
