import React from "react";
import { apiCall } from "../../_services/apiCall";
import config from "../../config/config.json";
import due from "../../img/Due.svg";
import updated from "../../img/updated.svg";
import done from "../../img/shape.svg";
import defaulted from "../../img/Defaulted.svg";
import { useState } from "react";
import { useEffect } from "react";
import TopComponentDiversity from "./TopComponentDiversity";
import SecondTopComponent from "./SecondTopComponent";
import DifferentlyAbled from "./DifferentlyAbled";
import WorkersIncludingDiAble from "./WorkersIncludingDiAble";
import TurnOverRate from "./TurnOverRate";
import EmployeeDifAbled from "./EmployeeDifAbled";
import RepresentationOfWomen from "./RepresenationWomen";
import PercentageOfPeopleReceivingMoreThanMinWages from "./PercentageOfPeopleReceivingMoreThanMinWages";
import DetailsOfMinimumWagesPaid from "./DetailsOfMinimumWagesPaid";
import PercentageOfWorkersReceivingMoreThanMinimumWages from "./PercentageOfWorkers";
import DetailsOfMinWorkers from "./DetailsOfMinWorker";
import EmployeeChart from "./EmployeesChart";
import TotalDifAbled from "./TotalDifAbled";
import TotalDifAbledWo from "./TotalDifAbWo";
import TotalDifWorkIncAb from "./TotalWorkIncDifAb";
import LeadershipRolesChart from "./LeadershipRolesChart";
import GenderDistributionInLeadership from "./GenderDistriInLeader";
import PromotionRatesByGender from "./PromotionRatesByGender";
import GenderPayGapAnalysis from "./GenderPayGapAnalysis";
import PercentageOfNewHires from "./PercentageOfNewHires";
import TotalEmplInclDifSingle from "./TotalEmplInclDifSingle";

const Diversity = ({
  locationOption,
  timePeriods,
  financialYearId,
  graphData,
  frameworkValue,
}) => {
  const location = 2;
  const [lastWeekAcitivities, setLastWeekAcitivities] = useState();
  const icons = {
    done: done,
    updated: updated,
    due: due,
    pending: defaulted,
  };

  const lastWeekActivity = async () => {
    const { isSuccess, data } = await apiCall(
      `${config.POSTLOGIN_API_URL_COMPANY}lastWeekActivity`,
      {},
      {},
      "GET"
    );
    if (isSuccess) {
      setLastWeekAcitivities(data?.data);
    }
  };

  useEffect(() => {
    lastWeekActivity();
  }, []);
  return (
    <div className="progress-container">
      <div className="topcompo">
        {lastWeekAcitivities && (
          <TopComponentDiversity
            lastWeekAcitivities={lastWeekAcitivities}
            icons={icons}
          />
        )}
      </div>

      {/* <div className="topcompo">
        {lastWeekAcitivities && (
          <SecondTopComponent
            lastWeekAcitivities={lastWeekAcitivities}
            icons={icons}
          />
        )}
      </div> */}
      {locationOption.length === 1 && (
        <div className="d-flex flex-column flex-space-between">
          <div
            className="d-flex flex-row flex-space-between"
            style={{ height: "70vh", marginBottom: "3%" }}
          >
            <TotalEmplInclDifSingle/>
          </div>
          <div
            className="d-flex flex-row flex-space-between"
            style={{ height: "70vh", marginBottom: "3%" }}
          >
            <TurnOverRate />
          </div>
          <div
            className="d-flex flex-row flex-space-between"
            style={{ height: "70vh", marginBottom: "3%" }}
          >
            <div className="firsthalfprogressenergy" style={{ width: "50%" }}>
              <EmployeeDifAbled />
            </div>
            <div className="secondhalfprogress" style={{ width: "50%" }}>
              <RepresentationOfWomen />
            </div>
          </div>
          <div
            className="d-flex flex-row flex-space-between"
            style={{ height: "70vh", marginBottom: "3%" }}
          >
            <div className="firsthalfprogressenergy" style={{ width: "50%" }}>
              <PercentageOfPeopleReceivingMoreThanMinWages />
            </div>
            <div className="secondhalfprogress" style={{ width: "50%" }}>
              <DetailsOfMinimumWagesPaid />
            </div>
          </div>
          <div
            className="d-flex flex-row flex-space-between"
            style={{ height: "70vh", marginBottom: "3%" }}
          >
            <div className="firsthalfprogressenergy" style={{ width: "50%" }}>
              <PercentageOfWorkersReceivingMoreThanMinimumWages />
            </div>
            <div className="secondhalfprogress" style={{ width: "50%" }}>
              <DetailsOfMinWorkers />
            </div>
          </div>
        </div>
      )}
      {locationOption.length >= 2 && (
        <div className="d-flex flex-column flex-space-between">
          <div
            className="d-flex flex-row flex-space-between"
            style={{ height: "70vh", marginBottom: "3%" }}
          >
            <div className="firsthalfprogressenergy" style={{ width: "50%" }}>
              <EmployeeChart
                locationOption={locationOption}
                timePeriods={timePeriods}
                financialYearId={financialYearId}
                graphData={graphData}
                frameworkValue={frameworkValue}
                horizontal={false}
                type="EMPLOYEE"
              />
            </div>
            <div className="secondhalfprogress" style={{ width: "50%" }}>
              <EmployeeChart
                locationOption={locationOption}
                timePeriods={timePeriods}
                financialYearId={financialYearId}
                graphData={graphData}
                frameworkValue={frameworkValue}
                horizontal={true}
                type="EMPLOYEE"
              />
            </div>
          </div>

          <div
            className="d-flex flex-row flex-space-between"
            style={{ height: "70vh", marginBottom: "3%" }}
          >
            <div className="firsthalfprogressenergy" style={{ width: "50%", height:"100%"}}>
              <EmployeeChart
                locationOption={locationOption}
                timePeriods={timePeriods}
                financialYearId={financialYearId}
                graphData={graphData}
                frameworkValue={frameworkValue}
                horizontal={true}
                type="WORKER"
              />
            </div>
            <div className="secondhalfprogress" style={{ width: "50%", height:"100%"}}>
              <EmployeeChart
                locationOption={locationOption}
                timePeriods={timePeriods}
                financialYearId={financialYearId}
                graphData={graphData}
                frameworkValue={frameworkValue}
                horizontal={false}
                type="EMPLOYEE"
              />
            </div>
          </div>
          {/* <div
            className="d-flex flex-row flex-space-between"
            style={{ height: "70vh", marginBottom: "3%" }}
          >
            <div className="firsthalfprogressenergy" style={{ width: "50%" }}>
           
              <LeadershipRolesChart
                locationOption={locationOption}
                timePeriods={timePeriods}
                financialYearId={financialYearId}
                graphData={graphData}
                frameworkValue={frameworkValue}
              />
            </div>
            <div className="secondhalfprogress" style={{ width: "50%" }}>
              <GenderDistributionInLeadership
                locationOption={locationOption}
                timePeriods={timePeriods}
                financialYearId={financialYearId}
                graphData={graphData}
                frameworkValue={frameworkValue}
              />
            </div>
          </div> */}
          {/* <div
            className="d-flex flex-row flex-space-between"
            style={{ height: "70vh", marginBottom: "3%" }}
          >
            <TurnOverRate></TurnOverRate>
          </div> */}
          {/* <div
            className="d-flex flex-row flex-space-between"
            style={{ height: "70vh", marginBottom: "3%" }}
          >
            <div className="firsthalfprogressenergy" style={{ width: "50%" }}>
              <PromotionRatesByGender />
            </div>
            <div className="secondhalfprogress" style={{ width: "50%" }}>
              <PercentageOfNewHires />
            </div>
          </div>
          <div
            className="d-flex flex-row flex-space-between"
            style={{ height: "70vh", marginBottom: "3%" }}
          >
            <GenderPayGapAnalysis />
          </div> */}
        </div>
      )}
    </div>
  );
};

export default Diversity;
